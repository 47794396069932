import React, {useCallback, useMemo, useState} from "react";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBTypography
} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import {SignaturePad} from "../../../components/SignaturePad";
import {GenericPage} from "../../../components/GenericPage";
import Icon from "@mdi/react";
import {
    mdiAlarmLightOutline,
    mdiEmail,
    mdiPlus,
    mdiTrashCan,
    mdiUndo,
    mdiWaterPlusOutline,
    mdiWrenchOutline
} from "@mdi/js";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {useParams} from "react-router-dom";
import {useServiceAdresHandtekeningGezet} from "../../../redux/slices/serviceAdressen/hooks";
import {Adres} from "../../../components/Adres";
import {SignaturePadWrapperOptions} from "react-signature-pad-wrapper";
import {Form, Formik, FormikProps} from "formik";
import {
    ExtraEmailAdres,
    KlantExtraCo2FlesBeleidEnum,
    PredefinedEmailAdres,
    PredefinedEmailAdresActieEnum
} from "../../../_generated/field-service-be-openapi";
import {Strikethrough} from "../../../components/Strikethrough";
import * as Yup from "yup";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {sumReducer} from "../../../utilities/reduceUtils";
import {useKlant} from "../../../redux/slices/klant/hooks";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {UnsavedChangesPrompt} from "../../../components/UnsavedChangesPrompt";
import {useTranslation} from "../../../utilities/i18nUtils";
import { BezoekMDBFormikTextArea } from "mdb-formik/bezoek/BezoekMDBFormikTextArea";
import {useGoBack} from "../../../routes/useGoBack";

const signaturePadOptions: SignaturePadWrapperOptions = {};

export interface HandtekeningFormValues {
    handtekeningImage?: string;
    opmerking?: string;

    predinedEmailAdressen: PredefinedEmailAdres[];
    extraEmailAdressen: ExtraEmailAdres[];

    nieuwEmailAdres: string;
    ondertekenaar: string;
}

export interface HandtekeningPageParams {
    bezoekSessieId: string;
}

export const HandtekeningPage: React.FC = () => {
    const {bezoekSessieId} = useParams<HandtekeningPageParams>();

    const [showEmailAdresToevoegen, setShowEmailAdresToevoegen] = useState(false);

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;
    const klant = useKlant(serviceAdres?.klantId);

    const handtekeningGezet = useServiceAdresHandtekeningGezet();

    const goBack = useGoBack();

    const onSubmit = useCallback((values: HandtekeningFormValues) => {
        if (bezoekSessie && values.handtekeningImage) {
            handtekeningGezet({
                bezoekSessieId: bezoekSessie.id,
                handtekening: values.handtekeningImage,
                opmerkingKlant: values.opmerking,
                predefinedEmailAdressen: values.predinedEmailAdressen,
                extraEmailAdressen: values.extraEmailAdressen,
                ondertekenaar: values.ondertekenaar
            });

            goBack();
        }
    }, [bezoekSessie, goBack, handtekeningGezet]);

    const extraCo2Leveringen = useMemo(() => {
        if (bezoekSessie) {
            return Object.values(bezoekSessie.co2Levering.leveringen).filter((item) => item.terPlaatse && (item.aantalGeleverd || item.aantalGebruiksrecht));
        }

        return [];
    }, [bezoekSessie]);

    const extraCo2LeveringenGebruiksrechtAantal = extraCo2Leveringen
        .filter(item => item.aantalGebruiksrecht > 0)
        .map(item => item.aantalGebruiksrecht)
        .reduce(sumReducer, 0);

    const aantalToestellen = bezoekSessie?.uitTeVoerenWerk?.toestellen?.length;
    const aantalOnderhouden = bezoekSessie?.uitTeVoerenWerk?.toestellen?.filter((item) => item.onderhoud).length;
    const aantalInterventies = bezoekSessie?.uitTeVoerenWerk?.toestellen?.map((toestel) => toestel.interventies?.length || 0).reduce((prev: number, curr: number) => prev + curr, 0);
    const aantalInstallaties = Object.values(bezoekSessie?.installaties || {}).filter(item => item.uitgevoerd).length;

    const initialValues: HandtekeningFormValues = useMemo(() => ({
        handtekeningImage: bezoekSessie?.handtekening?.image,
        opmerking: bezoekSessie?.handtekening?.opmerking || "",
        predinedEmailAdressen: bezoekSessie?.handtekening?.predefinedEmailAdressen ||
            serviceAdres?.contactEmailAdressen?.map(emailAdres => ({
                emailAdres,
                actie: PredefinedEmailAdresActieEnum.Verzenden
            } as PredefinedEmailAdres)) || [],
        extraEmailAdressen: bezoekSessie?.handtekening?.extraEmailAdressen || [],

        nieuwEmailAdres: "",
        ondertekenaar: bezoekSessie?.handtekening?.ondertekenaar || ""
    }), [bezoekSessie, serviceAdres?.contactEmailAdressen]);

    const {t} = useTranslation("serviceadres");

    const handtekeningIsVerplichtFoutmelding = t("HandtekeningPage.handtekening-is-verplicht", "Handtekening is verplicht");
    const geldigEmailAdresNodigFoutmelding = t("HandtekeningPage.gelieve-een-geldig-emailadres-in-te-vullen", "Gelieve een geldig e-mailadres in te vullen");
    const ondertekenaarIsVerplicht = t("HandtekeningPage.ondertekenaar-is-verplicht", "Ondertekenaar is verplicht");

    const validationSchema = useMemo(() => Yup.object({
        handtekeningImage: Yup.string().required(handtekeningIsVerplichtFoutmelding).min(0, handtekeningIsVerplichtFoutmelding).nullable(),
        opmerking: Yup.string().nullable(),

        predinedEmailAdressen: Yup.array(Yup.object({emailAdres: Yup.string().email(geldigEmailAdresNodigFoutmelding)})).nullable(),
        extraEmailAdressen: Yup.array(Yup.object({
            emailAdres: Yup.string().email(geldigEmailAdresNodigFoutmelding),
            actie: Yup.string()
        })).nullable(),

        nieuwEmailAdres: Yup.string().email("Gelieve een geldig e-mailadres in te vullen").optional().nullable(),
        ondertekenaar: Yup.string().required(ondertekenaarIsVerplicht).nullable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const onNieuwEmailAdresToevoegen = useCallback((formik: FormikProps<HandtekeningFormValues>) => {
        if (!formik.values.nieuwEmailAdres) {
            return;
        }

        const extraEmailAdressen = formik.values.extraEmailAdressen;

        formik.setFieldValue("extraEmailAdressen", [
            ...extraEmailAdressen,
            {emailAdres: formik.values.nieuwEmailAdres}
        ]);
        formik.setFieldValue("nieuwEmailAdres", "");
    }, []);

    const onExtraEmailAdresVerwijderen = useCallback((formik: FormikProps<HandtekeningFormValues>, email: ExtraEmailAdres) => {
        const extraEmailAdressen = formik.values.extraEmailAdressen;

        formik.setFieldValue("extraEmailAdressen", extraEmailAdressen.filter(item => item.emailAdres !== email.emailAdres));
    }, []);

    const onPredefinedEmailAdresVerwijderen = useCallback((formik: FormikProps<HandtekeningFormValues>, email: PredefinedEmailAdres) => {
        const predefinedEmailAdressen = formik.values.predinedEmailAdressen;

        formik.setFieldValue("predinedEmailAdressen", predefinedEmailAdressen.map(item => {
            if (item.emailAdres === email.emailAdres) {
                return {
                    emailAdres: item.emailAdres,
                    actie: PredefinedEmailAdresActieEnum.Verwijderen
                };
            }

            return item;
        }));
    }, []);

    const onPredefinedEmailAdresUndo = useCallback((formik: FormikProps<HandtekeningFormValues>, email: PredefinedEmailAdres) => {
        const predefinedEmailAdressen = formik.values.predinedEmailAdressen;

        formik.setFieldValue("predinedEmailAdressen", predefinedEmailAdressen.map(item => {
            if (item.emailAdres === email.emailAdres) {
                return {
                    emailAdres: item.emailAdres,
                    actie: PredefinedEmailAdresActieEnum.Verzenden
                };
            }

            return item;
        }));
    }, []);

    const nieuwEmailAdresLabel = t("HandtekeningPage.nieuw-emailadres", "Nieuw e-mailadres");
    const wieTekentErLabel = t("HandtekeningPage.wie-tekent-er", "Wie tekent er?");
    const opmerkingenKlantLabel = t("HandtekeningPage.opmerkingen-klant", "Opmerkingen klant");

    return (
        <GenericPage>
            <Header title={serviceAdres?.naam} subtitle={<Adres adres={serviceAdres}/>}/>

            <div className="container-lg pt-1 flex-fill d-flex flex-column bg-white">
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<HandtekeningFormValues> initialValues={initialValues} enableReinitialize validationSchema={validationSchema}
                                                onSubmit={onSubmit}>
                    {
                        (formik) => {
                            const {values, setFieldValue, dirty, isSubmitting} = formik;

                            return (
                                <Form autoComplete="off">
                                    <UnsavedChangesPrompt when={dirty && !isSubmitting}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <MDBCard background="light" className="mb-3 shadow-sm">
                                        <MDBCardBody className="pb-0">
                                            {extraCo2LeveringenGebruiksrechtAantal > 0 && (
                                                <MDBTypography note noteColor="warning" className="mb-0">
                                                    {t("HandtekeningPage.er-zijn-extra-co2flessen-geleverd",
                                                    "Er zijn {{aantal}} extra CO₂-flessen geleverd (i.p.v. omgeruild). Deze extra flessen worden anders gefactureerd.",
                                                        {
                                                            count: extraCo2LeveringenGebruiksrechtAantal,
                                                            aantal: extraCo2LeveringenGebruiksrechtAantal
                                                        })}
                                                </MDBTypography>
                                            )}

                                            <MDBTable responsive>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>{t("HandtekeningPage.artikel", "Artikel")}</th>
                                                        <th>{t("HandtekeningPage.aantal-geleverd", "Aantal geleverd")}</th>
                                                        <th>{t("HandtekeningPage.aantal-retour", "Aantal retour")}</th>

                                                        {klant?.extraCo2FlesBeleid === KlantExtraCo2FlesBeleidEnum.Gebruiksrecht && (
                                                            <th>{t("HandtekeningPage.aantal-gebruiksrecht", "Aantal gebruiksrecht")}</th>
                                                        )}
                                                        {klant?.extraCo2FlesBeleid === KlantExtraCo2FlesBeleidEnum.Waarborg && (
                                                            <th>{t("HandtekeningPage.aantal-waarborg", "Aantal waarborg")}</th>
                                                        )}
                                                        {klant?.extraCo2FlesBeleid === KlantExtraCo2FlesBeleidEnum.Geen && (
                                                            <th>{t("HandtekeningPage.aantal-extra", "Aantal extra")}</th>
                                                        )}
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {extraCo2Leveringen?.map((levering) => {
                                                        const artikel = serviceAdres?.co2Artikelen?.find((item) => item.id === levering.artikelId);

                                                        return (
                                                            <tr key={levering.artikelId}>
                                                                <td>
                                                                    <strong>{artikel?.nummer}</strong> {artikel?.omschrijving}
                                                                </td>
                                                                <td>
                                                                    {levering.aantalGeleverd}
                                                                </td>
                                                                <td>
                                                                    {levering.aantalOmruilGeleverd}
                                                                </td>
                                                                {klant?.extraCo2FlesBeleid && [KlantExtraCo2FlesBeleidEnum.Gebruiksrecht, KlantExtraCo2FlesBeleidEnum.Waarborg].includes(klant?.extraCo2FlesBeleid) && (
                                                                    <td>
                                                                        {levering.aantalGebruiksrecht}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        );
                                                    })}

                                                    {!extraCo2Leveringen?.length && (
                                                        <tr>
                                                            <td colSpan={4}>{t("HandtekeningPage.geen-extra-leveringen", "Geen extra leveringen")}</td>
                                                        </tr>
                                                    )}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </MDBCardBody>

                                        <MDBCardBody className="pt-0">
                                            <div className="mb-2">
                                                <strong>{t("HandtekeningPage.toestellen",
                                                    "Toestellen",
                                                    {count: aantalToestellen})}
                                                </strong></div>

                                            <div>
                                                {!!aantalOnderhouden && (
                                                    <div className="d-flex align-items-center mb-2 me-2">
                                                        <Icon path={mdiWrenchOutline} size={1}
                                                              className="text-primary me-1"/>
                                                        {aantalOnderhouden} <MDBBadge color="secondary"
                                                                                      className="ms-2">{t("HandtekeningPage.onderhoud", "Onderhoud")}</MDBBadge>
                                                    </div>
                                                )}

                                                {!!aantalInterventies && (
                                                    <div className="d-flex align-items-center mb-2 me-2">
                                                        <Icon path={mdiAlarmLightOutline} size={1}
                                                              className="text-primary me-1"/>
                                                        {aantalInterventies} <MDBBadge color="secondary"
                                                                                       className="ms-2">{t("HandtekeningPage.interventie", "Interventie")}</MDBBadge>
                                                    </div>
                                                )}

                                                {!!aantalInstallaties && (

                                                    <div className="d-flex align-items-center mb-2 me-2">
                                                        <Icon path={mdiWaterPlusOutline} size={1}
                                                              className="text-primary me-1"/>
                                                        {aantalInstallaties} <MDBBadge color="secondary"
                                                                                       className="ms-2">{t("HandtekeningPage.installatie", "Installatie")}</MDBBadge>
                                                    </div>
                                                )}
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>

                                    <MDBRow className="mb-3">
                                        <MDBCol size="12">
                                            <BezoekMDBFormikTextArea name="opmerking" label={opmerkingenKlantLabel}
                                                                  rows={4}/>
                                        </MDBCol>
                                    </MDBRow>

                                    <div className="mb-1"><Icon path={mdiEmail} size={1}/> {t("HandtekeningPage.servicerapport-mailen-naar", "Servicerapport mailen naar")}
                                    </div>
                                    <MDBRow className="mb-2">
                                        <MDBCol>
                                            <MDBListGroup>
                                                {values.predinedEmailAdressen.map(email => (
                                                    <MDBListGroupItem key={email.emailAdres}
                                                                      className="d-flex align-items-center justify-content-between px-2 py-0">
                                                        <div>
                                                            <Strikethrough
                                                                strikethrough={email.actie === PredefinedEmailAdresActieEnum.Verwijderen}>{email.emailAdres}</Strikethrough>
                                                        </div>

                                                        <div>
                                                            {email.actie === PredefinedEmailAdresActieEnum.Verzenden && (
                                                                <MDBBtn type="button" color="link" size="sm"
                                                                        onClick={() => onPredefinedEmailAdresVerwijderen(formik, email)}>
                                                                    <Icon path={mdiTrashCan} size={1}/>
                                                                </MDBBtn>
                                                            )}

                                                            {email.actie === PredefinedEmailAdresActieEnum.Verwijderen && (
                                                                <MDBBtn type="button" color="link" size="sm"
                                                                        onClick={() => onPredefinedEmailAdresUndo(formik, email)}>
                                                                    <Icon path={mdiUndo} size={1}/>
                                                                </MDBBtn>
                                                            )}
                                                        </div>
                                                    </MDBListGroupItem>
                                                ))}

                                                {values.extraEmailAdressen.map(email => (
                                                    <MDBListGroupItem key={email.emailAdres}
                                                                      className="d-flex align-items-center justify-content-between px-2 py-0">
                                                        <div>
                                                            {email.emailAdres}
                                                        </div>

                                                        <MDBBtn type="button" color="link" size="sm"
                                                                onClick={() => onExtraEmailAdresVerwijderen(formik, email)}>
                                                            <Icon path={mdiTrashCan} size={1}/>
                                                        </MDBBtn>
                                                    </MDBListGroupItem>
                                                ))}
                                            </MDBListGroup>
                                        </MDBCol>

                                        <MDBCol size="auto">
                                            <MDBBtn type="button" color="link" size="lg" className="p-2"
                                                    onClick={() => setShowEmailAdresToevoegen(!showEmailAdresToevoegen)}>
                                                <Icon path={mdiPlus} size={1}/>
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>

                                    {showEmailAdresToevoegen && (
                                        <MDBRow className="mb-4">
                                            <MDBCol size="12">
                                                <MDBRow>
                                                    <MDBCol offsetSm="1">
                                                        <BezoekMDBFormikInput label={nieuwEmailAdresLabel}
                                                                              name="nieuwEmailAdres"/>
                                                    </MDBCol>
                                                    <MDBCol size="2">
                                                        <MDBBtn type="button" block
                                                                className="d-flex align-items-center"
                                                                disabled={!!formik.errors.nieuwEmailAdres}
                                                                onClick={() => {
                                                                    onNieuwEmailAdresToevoegen(formik);
                                                                    setShowEmailAdresToevoegen(false);
                                                                }}>
                                                            Toevoegen
                                                        </MDBBtn>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                    )}

                                    <MDBRow className="mt-4 mb-4">
                                        <MDBCol className="mb-3">
                                            <BezoekMDBFormikInput label={wieTekentErLabel} name="ondertekenaar"/>
                                        </MDBCol>
                                        <MDBCol size="12">
                                            <SignaturePad height={400}
                                                          onEndImage={(image) => setFieldValue("handtekeningImage", image)}
                                                          initialImage={values.handtekeningImage}
                                                          error={formik.errors.handtekeningImage}
                                                          options={signaturePadOptions}
                                                          disabled={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}
                                            />
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow className="mb-2">
                                        <MDBCol size="12">
                                            <BezoekMDBBtn block size="lg" type="submit">{t("HandtekeningPage.bewaren", "Bewaren")}</BezoekMDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </Form>
                            );
                        }
                    }
                </Formik>
            </div>
        </GenericPage>
    );
};
