import React, {useCallback} from "react";
import {MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBCol, MDBRow} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiInformationOutline, mdiNewspaperVariantOutline} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useParams} from "react-router-dom";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {Form, Formik} from "formik";
import {useMe} from "../../../redux/slices/technicus/hooks";
import {useToestel, useToestelWaterstandOpgenomen} from "../../../redux/slices/toestellen/hooks";
import {WaterstandOpgenomenResultaatEnum} from "../../../_generated/field-service-be-openapi";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBFormikCheckbox} from "../../../mdb-formik/bezoek/BezoekMDBFormikCheckbox";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {BezoekMDBFormikInput} from "../../../mdb-formik/bezoek/BezoekMDBFormikInput";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {UnsavedChangesPrompt} from "../../../components/UnsavedChangesPrompt";
import {useTranslation} from "../../../utilities/i18nUtils";
import {useGoBack} from "../../../routes/useGoBack";

export interface ToestelCheckInFormValues {
    meterstand?: number;
    geenMeterstand?: boolean;
}

export interface WaterstandOpnemenPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const WaterstandOpnemenPage: React.FC = () => {
    const goBack = useGoBack();

    const {bezoekSessieId, toestelId} = useParams<WaterstandOpnemenPageParams>();

    const toestel = useToestel(toestelId);
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const technicus = useMe();
    const waterstandOpgenomen = useToestelWaterstandOpgenomen();

    const onSubmit = useCallback((values: ToestelCheckInFormValues) => {
        if (!bezoekSessie || !technicus) {
            return;
        }

        if (values.geenMeterstand === true || values.meterstand) {
            if (values.geenMeterstand) {
                waterstandOpgenomen(bezoekSessie.id, toestelId, WaterstandOpgenomenResultaatEnum.GeenMeterAanwezig);
            } else {
                waterstandOpgenomen(bezoekSessie.id, toestelId, WaterstandOpgenomenResultaatEnum.MeterstandOpgenomen, values.meterstand);
            }

            goBack();
        }
    }, [bezoekSessie, waterstandOpgenomen, goBack, technicus, toestelId]);

    const initialValues: ToestelCheckInFormValues = {
        meterstand: toestel && bezoekSessie?.toestellen[toestel.id]?.waterstand?.meterstand,
        geenMeterstand: toestel && bezoekSessie?.toestellen[toestel.id]?.waterstand?.resultaat === WaterstandOpgenomenResultaatEnum.GeenMeterAanwezig
    };

    const {t} = useTranslation("serviceadres");
    const geenWaterMeterAanwezigLabel = t("geen-watermeter-aanwezig", "Geen watermeter aanwezig")
    const waterstandLabel = t("waterstand", "Waterstand")

    if (!serviceAdres || !toestel) {
        return null;
    }

    return (
        <GenericPage>
            <Header title={t("WaterstandOpnemenPage.waterstand-opnemen", "Waterstand opnemen")}
                    subtitle={toestel?.locatieBeschrijving}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader>
                        <Icon path={mdiInformationOutline} size={1}/> Toestelinformatie
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.toestel", "Toestel:")}</strong><br/> {toestel.omschrijving}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.nummer", "Nummer:")}</strong><br/> {toestel.nr}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.serienummer", "Serienummer:")} </strong><br/> {toestel.serieNummer}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.installatiedatum", "Installatiedatum:")} </strong><br/> {toestel.installatieDatum}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("toestel:Labels.type", "Type:")}</strong><br/> {toestel.transactieType}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <Formik<ToestelCheckInFormValues> initialValues={initialValues} onSubmit={onSubmit}>
                    {
                        ({values, dirty, isSubmitting}) => {
                            return (
                                <Form autoComplete="off">
                                    <UnsavedChangesPrompt when={dirty && !isSubmitting}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <h5><Icon path={mdiNewspaperVariantOutline} size={1}
                                              className="me-2"/> {t("algemeen:formulier", "Formulier")}</h5>
                                    <hr/>

                                    <MDBRow>
                                        <MDBCol size="12" className="mb-2">
                                            <BezoekMDBFormikInput type="number" name="meterstand"
                                                                  label={waterstandLabel}
                                                                  disabled={values.geenMeterstand}/>
                                        </MDBCol>

                                        <MDBCol size="12" className="ms-2 mb-4">
                                            <BezoekMDBFormikCheckbox id="geenMeterstand" name="geenMeterstand"
                                                                     label={geenWaterMeterAanwezigLabel}/>
                                        </MDBCol>

                                        <MDBCol>
                                            <BezoekMDBBtn size="lg" block type="submit"
                                                          className="mt-2">{t("algemeen:bewaren", "Bewaren")}</BezoekMDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </Form>
                            );
                        }
                    }
                </Formik>
            </ContentContainer>
        </GenericPage>
    );
};
