import React from "react";
import {MDBAlert, MDBBtn, MDBCol, MDBRow} from "../../mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAlert, mdiCheckCircle} from "@mdi/js";
import {FaucetSignalIcon} from "./FaucetSignalIcon";
import {BezoekSessieToestelIot} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ToestelIotActivatedCardProps {
    iot?: BezoekSessieToestelIot;

    onAanpassenClick(): void;

    onVerwijderenClick(): void;
}

export const ToestelIotActivatedCard: React.FC<ToestelIotActivatedCardProps> = (props) => {
    const {iot, onAanpassenClick, onVerwijderenClick} = props;

    const {t} = useTranslation("toestel");

    const hasErrors = !!iot?.informatie?.errors?.length;

    return (
        <MDBAlert color={hasErrors ? "warning" : "success"} open>
            <MDBRow>
                <MDBCol size="auto">
                    <Icon path={hasErrors ? mdiAlert : mdiCheckCircle}
                          color={hasErrors ? "var(--mdb-warning)" : "var(--mdb-success)"} size={5}/>
                </MDBCol>
                <MDBCol className="p-3">
                    {!hasErrors &&
                        <div>{t("Labels.iot.deze-installatie-bevat-een-iot-enabled-toestel-dat-correct-geactiveerd-werd", "Deze installatie bevat een IoT-enabled toestel dat correct geactiveerd werd.")}</div>}
                    {hasErrors && (
                        <div>
                            <p>{t("Labels.iot.deze-installatie-bevat-een-iot-enabled-toestel-dat-correct-geactiveerd werd-maar-met-errors", "Deze installatie bevat een IoT-enabled toestel dat correct geactiveerd werd, maar error boodschappen teruggeeft")}</p>
                            <p>{t("Labels.iot.deze-hardware-moet-bij-de-klant-achtergelaten-worden", "Deze hardware moet bij de klant achtergelaten worden. Via 'Aanpassen' kan je eventuele errors oplossen.")}</p>
                        </div>
                    )}
                    <div>
                        <div>
                            <strong>{t("Labels.iot.platform", "Platform:")} </strong> {iot?.informatie?.iotPlatformName}
                        </div>
                        <div>
                            <strong>{t("Labels.iot.toesteltype", "Toesteltype:")} </strong> {iot?.informatie?.iotDeviceType}
                        </div>
                        <div>
                            <strong>{t("Labels.iot.hardware-id", "Hardware-id:")} </strong> {iot?.informatie?.hardwareId}
                        </div>
                        <div>
                            <strong>{t("Labels.iot.soft-device-id", "Soft device-id:")} </strong> {iot?.informatie?.softDeviceId}
                        </div>
                    </div>
                    {!!iot?.informatie?.options?.length && (
                        <div>
                            <strong>{t("Labels.iot.geinstalleerde-opties", "Geïnstalleerde opties")}</strong>
                            <ul>
                                {iot?.informatie?.options?.map(option => (
                                    <li><strong>{option.type}</strong> <span>{option.hardwareId}</span></li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {hasErrors && (
                        <div>
                            <strong>{t("Labels.iot.het-toestel-geeft-volgende-errors", "Het toestel geeft volgende errors:")}</strong>
                            <ul>
                                {iot?.informatie?.errors?.map(error => (
                                    <li><strong>{error.code}</strong> <span>{error.description}</span></li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className="mt-2">
                        <MDBBtn color="primary" type="button" className="me-2"
                                onClick={onAanpassenClick}>{t("Labels.aanpassen", "Aanpassen")}</MDBBtn>
                        <MDBBtn color="light" type="button"
                                onClick={onVerwijderenClick}>{t("Labels.verwijderen", "Verwijderen")}</MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
            <div style={{position: "absolute", right: 0, top: 12, bottom: 12, width: 160}}>
                <FaucetSignalIcon/>
            </div>
        </MDBAlert>
    );
};
