import {Config} from "../config";
import {DeviceError} from "./types";

export class IotUtils {

    static buildOnboardNewDeviceUrl(klantId: string, verzendAdresId: string, options: DeviceError[], returnUrl: string) {
        const searchParams = new URLSearchParams({
            action: "ONBOARD_DEVICE",
            customerId: klantId,
            shippingAddressId: verzendAdresId,
            options: btoa(JSON.stringify(options)),
            callbackUrl: returnUrl
        });

        return `${Config.IOT_INSTALLER_URL}/?${searchParams.toString()}`;
    }

    static buildDetachDeviceUrl(toestelId: string, returnUrl: string) {
        const searchParams = new URLSearchParams({
            action: "DETACH_DEVICE",
            deviceAtCustomerId: toestelId,
            callbackUrl: returnUrl
        });

        return `${Config.IOT_INSTALLER_URL}/?${searchParams.toString()}`;
    }

    static buildEditUrl(toestelId: string, returnUrl: string) {
        const searchParams = new URLSearchParams({
            action: "EDIT_DEVICE",
            deviceAtCustomerId: toestelId,
            callbackUrl: returnUrl
        });

        return `${Config.IOT_INSTALLER_URL}/?${searchParams.toString()}`;
    }

}
