import React, {CSSProperties, ReactNode} from "react";
import {
    MDBCol,
    MDBContainer,
    MDBNavbar,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBRow
} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiArrowLeft, mdiMenu} from "@mdi/js";
import {motion, useTransform} from "framer-motion";
import {useInitialDimensions} from "../utilities/useInitialDimensions";
import {useShowSidenav} from "../redux/slices/ui/hooks";
import {MotionMDBNavbar} from "./mdb/MotionMDBNavbar";
import {useViewportScrollForElement} from "../utilities/animationUtils";
import {HeaderLoadingStatus} from "./HeaderLoadingStatus";
import {HeaderSyncStatus} from "./HeaderSyncStatus";
import {useGoBack} from "../routes/useGoBack";
import {useParentRoutePath} from "../routes/useParentRoutePath";
import {useCurrentRoute} from "../routes/useCurrentRoute";

export interface HeaderProps {
    title?: ReactNode;
    subtitle?: ReactNode;
    sticky?: boolean;
    shadow?: boolean;

    background?: string;
    color?: string;

    className?: string;
    style?: CSSProperties;
}

export const Header: React.FC<HeaderProps> = (props) => {
    const {
        title,
        subtitle,
        sticky,
        shadow,
        background = "#fff",
        color = "var(--mdb-primary)",
        className,
        style
    } = props;

    const showSidenav = useShowSidenav();

    const [subnavRef, subnavSize] = useInitialDimensions();
    const [subtitleRef, subtitleSize] = useInitialDimensions();

    const currentRoute = useCurrentRoute();
    const parentRoutePath = useParentRoutePath();
    const goBack = useGoBack();

    const {scrollY} = useViewportScrollForElement(document.getElementById("content")!);

    const y = useTransform(scrollY, [0, subnavSize.height], [0, subtitleSize.height - 8]);

    const titleBoxShadow = useTransform(scrollY, value => {
        if (value > subnavSize.height || shadow) {
            return "0 5px 10px rgba(0, 0, 0, 0.15)";
        }

        return "none";
    });
    const titleOpacity = useTransform(scrollY, value => value >= subnavSize.height ? 1 : 0);
    const titlePointerEvents = useTransform(scrollY, value => value > subnavSize.height ? "auto" : "none");
    const subnavTitleOpacity = useTransform(scrollY, value => value > subnavSize.height ? 0 : 1);
    const subnavSubtitleOpacity = useTransform(scrollY, [0, subnavSize.height / 2], [1, 0]);

    const scale = useTransform(scrollY, [0, subnavSize.height], [1, 1.3 / 1.6]);

    if (!currentRoute) {
        return null;
    }

    const onSideNavToggle = () => {
        showSidenav();
    };

    const onBackClick = () => {
        goBack();
    };

    const hasBack = currentRoute.useParentToGoBack === false || !!parentRoutePath;

    return (
        <>
            <MotionMDBNavbar sticky={sticky || !!subtitle} dark expand
                             className={`top-navbar ${className || ""}`}
                             style={{...style, background, boxShadow: titleBoxShadow as any}}>
                <MDBContainer fluid>
                    <MDBNavbarNav left className="flex-row ps-2 text-primary align-items-center">
                        <MDBNavbarItem className="flex-fill">
                            <MDBRow>
                                <MDBCol size="auto" className="pe-0 d-flex align-items-center">
                                    <MDBNavbarItem tag="div">
                                        {hasBack && (
                                            <MDBNavbarLink className="d-flex align-items-center py-1"
                                                           onClick={onBackClick}>
                                                <Icon path={mdiArrowLeft} size={1} color={color} className="me-4"/>
                                            </MDBNavbarLink>
                                        )}
                                    </MDBNavbarItem>
                                </MDBCol>

                                <MDBCol className="ps-0">
                                    {/* We're using a fixed font size here and scale so non-text items can also be used and scale well */}
                                    <motion.div style={{
                                        fontSize: "1.6rem",
                                        transformOrigin: "left",
                                        scale: 1.3 / 1.6,
                                        opacity: titleOpacity,
                                        willChange: "opacity",
                                        pointerEvents: titlePointerEvents
                                    }}>
                                        {title}
                                        {!title && currentRoute.nameComponent && <currentRoute.nameComponent/>}
                                        {!title && currentRoute.name}
                                    </motion.div>
                                </MDBCol>
                            </MDBRow>
                        </MDBNavbarItem>

                        <MDBNavbarItem className="me-2">
                            <HeaderLoadingStatus/>
                        </MDBNavbarItem>

                        <MDBNavbarItem className="text-warning d-flex align-items-center text-nowrap me-2">
                            <HeaderSyncStatus/>
                        </MDBNavbarItem>

                        <MDBNavbarItem>
                            <MDBNavbarToggler
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={onSideNavToggle}
                            >
                                <Icon path={mdiMenu} color={color} size={1}/>
                            </MDBNavbarToggler>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBContainer>
            </MotionMDBNavbar>

            {subtitle && (
                <MDBNavbar dark expand className={`top-navbar pt-0 pb-0 ${!shadow ? "shadow-none" : ""}`}
                           ref={subnavRef} style={{background}}>
                    <MDBContainer fluid>
                        <MDBNavbarNav left className="flex-row ps-2 text-primary">
                            <MDBNavbarItem className="flex-fill">
                                <MDBRow>
                                    {hasBack && <MDBCol size="auto" className="pe-0 me-5"/>}

                                    <MDBCol className={hasBack ? "ps-0" : undefined} style={{zIndex: 2000}}>
                                        <motion.div style={{
                                            y,
                                            scale,
                                            opacity: subnavTitleOpacity,
                                            fontSize: "1.6rem",
                                            transformOrigin: "left",
                                            willChange: "transform, opacity"
                                        }} className="pt-0">
                                            {title}
                                            {!title && currentRoute.nameComponent && <currentRoute.nameComponent/>}
                                            {!title && currentRoute.name}
                                        </motion.div>
                                        <motion.div style={{opacity: subnavSubtitleOpacity}} ref={subtitleRef}>
                                            {subtitle}
                                        </motion.div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBContainer>
                </MDBNavbar>
            )}
        </>
    );
};
