import {generatePath, useParams} from "react-router-dom";

import {useCurrentRoute} from "./useCurrentRoute";

export const useParentRoutePath = () => {
    const currentRoute = useCurrentRoute();
    const params = useParams();

    if (!currentRoute?.parent || currentRoute.useParentToGoBack === false) {
        return null;
    }

    if (currentRoute.getParentRoutePath) {
        return currentRoute.getParentRoutePath(currentRoute, params);
    } else {
        return generatePath(currentRoute.parent.path, params);
    }
};
