import React from "react";
import {MDBCol, MDBRow} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import {useParams} from "react-router-dom";
import {GenericPage} from "../../../components/GenericPage";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {ToestelServiceLogList} from "../../../components/toestel/servicelog/ToestelServiceLogList";
import {useTranslation} from "../../../utilities/i18nUtils";
import {ToestelLocatie} from "../../../components/toestel/ToestelLocatie";

export interface ToestelServiceLogPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelServiceLogPage: React.FC = () => {
    const {bezoekSessieId, toestelId} = useParams<ToestelServiceLogPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const bezoekSessieToestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);

    const {t} = useTranslation("toestel");

    return (
        <GenericPage>
            <Header title={t("algemeen:Titels.servicelog", "Servicelog")} subtitle={<ToestelLocatie toestel={bezoekSessieToestel?.informatie} br={false}/>}/>

            <div className="container-lg pt-3 flex-fill bg-white">
                <MDBRow>
                    <MDBCol>
                        {bezoekSessieToestel?.informatie &&
                            <ToestelServiceLogList toestel={bezoekSessieToestel.informatie}/>}
                    </MDBCol>
                </MDBRow>
            </div>
        </GenericPage>
    );
};
