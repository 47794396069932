import {useHistory} from "react-router-dom";
import {useCallback} from "react";

import {useParentRoutePath} from "./useParentRoutePath";

export const useGoBack = () => {
    const history = useHistory();
    const parentRoutePath = useParentRoutePath();

    return useCallback(() => {
        if (parentRoutePath) {
            history.replace(parentRoutePath);
        } else {
            history.goBack();
        }
    }, [history, parentRoutePath]);
};
