import React, {MouseEvent} from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBRipple,
    MDBRow
} from "mdb-react-ui-kit";
import {NavLink} from "react-router-dom";
import {MDBCardStatus, MDBCardStatusEnum} from "../../../components/mdb/MDBCardStatus";
import Icon from "@mdi/react";
import {mdiAccountCircle, mdiAlarmLightOutline, mdiChevronRight, mdiWrenchOutline} from "@mdi/js";
import {LocationPin} from "../../../components/LocationPin";
import {MDBChip} from "../../../components/mdb/MDBChip";
import {Technicus, Toestel, ToestelOnderhoudTeVervangenFilter} from "_generated/field-service-be-openapi";
import {useTranslation} from "../../../utilities/i18nUtils";
import {ToestelLocatie} from "../../../components/toestel/ToestelLocatie";

export interface ServiceAdresToestelCardProps {
    toestel?: Toestel;
    link?: string;
    linkOnClick?: (event?: MouseEvent<HTMLAnchorElement>) => any;

    active?: boolean;

    onderhoud?: boolean;
    onderhoudFiltersVervangen?: Array<ToestelOnderhoudTeVervangenFilter>;
    interventie?: boolean;

    cardStatusColor?: MDBCardStatusEnum;

    actieveTechnicus?: Technicus;
}

const OptionalNavLink: React.FC<{ to?: string; onClick?: (event?: MouseEvent<HTMLAnchorElement>) => any; children?: React.ReactNode; }> = ({
                                                                                                                  to,
                                                                                                                  onClick,
                                                                                                                  children
                                                                                                              }) => {
    if (to) {
        return <NavLink to={to} onClick={onClick}>{children}</NavLink>;
    }

    return <>{children}</>;
};

export const ServiceAdresToestelCard: React.FC<ServiceAdresToestelCardProps> = (props) => {
    const {toestel, link, linkOnClick, active, onderhoud, onderhoudFiltersVervangen, interventie, cardStatusColor, actieveTechnicus} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBCard background="light" className="mt-2 mb-4 shadow-sm">
            <OptionalNavLink to={link} onClick={linkOnClick}>
                <MDBRipple rippleColor="secondary" rippleTag="div" className="w-100">
                    <MDBCardStatus status={cardStatusColor}/>
                    <MDBRow>
                        <MDBCol size="4" className="ps-4 py-3 pe-0">
                            <div style={{
                                backgroundColor: "#fff",
                                backgroundImage: `url(${toestel?.afbeeldingUri})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                width: "100%",
                                height: "100%",
                                borderRadius: "0.25rem"
                            }}/>
                        </MDBCol>
                        <MDBCol>
                            <MDBCardHeader>
                                <MDBRow>
                                    <MDBCol>
                                        <span className="d-flex align-items-center mt-1 mb-1">
                                            {active && <LocationPin className="me-3"/>}
                                            <ToestelLocatie toestel={toestel}/>
                                        </span>
                                    </MDBCol>

                                    <MDBCol size="auto" className="d-flex">
                                        {actieveTechnicus && (
                                            <MDBChip className="my-0 me-0">
                                                <Icon path={mdiAccountCircle} size={1}/>

                                                {actieveTechnicus.naam}
                                            </MDBChip>
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBCardText>
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBRow>
                                                <MDBCol sm="4" className="mb-2">
                                                    <strong>{t("Labels.serienummer", "Serienummer:")}</strong><br/> {toestel?.serieNummer}
                                                </MDBCol>
                                                <MDBCol sm="4" className="mb-2">
                                                    <strong>{t("ServiceAdresToestelCard.toestel", "Toestel: ")}</strong><br/>
                                                    {toestel?.omschrijving || t("algemeen:Titels.onbekend", "Onbekend")}
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>

                                        {link && (
                                            <MDBCol size="auto"
                                                    className="d-flex align-items-center justify-content-end">
                                                <Icon path={mdiChevronRight} size={2}/>
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                            {(onderhoud || interventie) && (
                                <MDBCardFooter className="d-flex">
                                    {onderhoud && (
                                        <span className="d-flex align-items-center mb-2 me-2">
                                            <Icon path={mdiWrenchOutline} size={1}
                                                  className="text-primary me-1"/>
                                            Onderhoud
                                            {onderhoudFiltersVervangen && onderhoudFiltersVervangen.length > 0 && (
                                                <>{' '}met vervangen:{' '}
                                                    {onderhoudFiltersVervangen.map(filter => `${filter.artikelOmschrijving} (${filter.artikelNummer})`).join(", ")}
                                                </>
                                            )}
                                        </span>
                                    )}
                                    {interventie && (
                                        <span className="d-flex align-items-center mb-2 me-2">
                                            <Icon path={mdiAlarmLightOutline} size={1}
                                                  className="text-primary me-1"/>
                                            Interventie
                                        </span>
                                    )}
                                </MDBCardFooter>
                            )}
                        </MDBCol>
                    </MDBRow>
                </MDBRipple>
            </OptionalNavLink>
        </MDBCard>
    );
};
