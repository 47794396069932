import React from "react";
import {MDBAlert, MDBBtn, MDBCol, MDBRow} from "../../mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiAlert} from "@mdi/js";
import {FaucetSignalIcon} from "./FaucetSignalIcon";
import {useTranslation} from "../../utilities/i18nUtils";

export interface ToestelIotInitialCardProps {
    onActiverenClick(): void;
}

export const ToestelIotInitialCard: React.FC<ToestelIotInitialCardProps> = (props) => {
    const {onActiverenClick} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBAlert color="warning" open style={{position: "relative"}}>
            <MDBRow>
                <MDBCol size="auto">
                    <Icon path={mdiAlert} color="var(--mdb-warning)" size={5}/>
                </MDBCol>
                <MDBCol className="p-3">
                    <div>{t("Labels.deze-installatie-bevat-een-iot-enabled-toestel-dat-nog-niet-geactiveerd-is", "Deze installatie bevat een IoT-enabled toestel dat nog niet geactiveerd is.")}</div>
                    <div className="mt-2">
                        <MDBBtn color="primary" type="button"
                                onClick={onActiverenClick}>{t("Labels.activeren", "Activeren")}</MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
            <div style={{position: "absolute", right: 0, top: 12, bottom: 12, width: 160}}>
                <FaucetSignalIcon/>
            </div>
        </MDBAlert>
    );
};
