import React from "react";
import {generatePath, Route, RouteProps} from "react-router-dom";
import {HomePage} from "../pages/HomePage";
import {ToestelPage} from "../pages/ServiceAdres/Toestel/ToestelPage";
import {ToestelOpdrachtPage} from "../pages/ServiceAdres/Opdracht/ToestelOpdrachtPage";
import {HandtekeningPage} from "../pages/ServiceAdres/Opdracht/HandtekeningPage";
import {OpdrachtPage, OpdrachtPageName} from "../pages/ServiceAdres/Opdracht/OpdrachtPage";
import {ScanToestelPage} from "../pages/ServiceAdres/Opdracht/ScanToestelPage";
import {BezoeksessiesPage} from "../pages/BezoeksessiesPage";
import {ServiceAdresDetailPage} from "../pages/ServiceAdres/ServiceAdresDetailPage";
import {ServiceAdresTakenPage} from "../pages/ServiceAdres/ServiceAdresTakenPage";
import {ToestelFotoPage} from "../pages/ServiceAdres/Toestel/ToestelFotoPage";
import {OfflinebeheerPage} from "../pages/OfflinebeheerPage";
import {OverDezeApplicatePage} from "../pages/OverDezeApplicatiePage";
import {DocumentenPage} from "../pages/DocumentenPage";
import {ShiftenPage} from "../pages/ShiftenPage";
import {ZoekToestelPage} from "../pages/ZoekToestelPage";
import {ToestelCheckInPage} from "../pages/ServiceAdres/Opdracht/ToestelCheckInPage";
import {WaterstandOpnemenPage} from "../pages/ServiceAdres/Opdracht/WaterstandOpnemenPage";
import {InternPage} from "../pages/ServiceAdres/Opdracht/InternPage";
import {InterventiePage} from "../pages/ServiceAdres/Opdracht/InterventiePage";
import {ToestelServiceLogPage} from "../pages/ServiceAdres/Toestel/ToestelServiceLogPage";
import {ServiceAdresServiceRapportenPage} from "../pages/ServiceAdres/ServiceAdresServiceRapportenPage";
import {ServiceAdresOverzichtPage} from "../pages/ServiceAdres/ServiceAdresOverzichtPage";
import {PlanningOverzichtPage} from "../pages/PlanningOverzichtPage";
import {InstallatiePage} from "../pages/ServiceAdres/Opdracht/InstallatiePage";
import {ServiceAdresBijlagenPage} from "../pages/ServiceAdres/ServiceAdresBijlagenPage";
import {ToestelBijlagenPage} from "../pages/ServiceAdres/Toestel/ToestelBijlagenPage";
import {ToestelStuklijstPage} from "../pages/ServiceAdres/Toestel/ToestelStuklijstPage";
import {LokaleGebeurtenissenPage} from "../pages/LokaleGebeurtenissenPage";
import {FoutmeldingenPage} from "../pages/FoutmeldingenPage";
import {InstallatieDossierPage} from "../pages/ServiceAdres/Opdracht/InstallatieDossierPage";
import {CallbackRoute} from "./CallbackRoute";

export interface RouteItem {
    key: string;
    path: string;
    exact?: boolean;
    component: React.ComponentType;
    route: React.ComponentType<RouteProps>; //React.ComponentType<RouteProps>;
    name?: string;
    nameComponent?: React.ComponentType;

    routes?: RouteItem[];

    parent?: RouteItem;
    useParentToGoBack?: boolean;
    getParentRoutePath?: (item: RouteItem, params: any) => string;
}

export const routes: RouteItem[] = [
    {
        key: "home",
        path: "/",
        exact: true,
        component: HomePage,
        route: Route,

        routes: [
            {
                key: "detail",
                path: "/bezoek/:bezoekSessieId",
                exact: true,
                component: ServiceAdresDetailPage,
                route: Route,
                useParentToGoBack: false,

                routes: [
                    {
                        key: "serviceadres-overzicht",
                        path: "/bezoek/:bezoekSessieId/overzicht",
                        exact: true,
                        component: ServiceAdresOverzichtPage,
                        route: Route
                    },
                    {
                        key: "servicerapporten",
                        path: "/bezoek/:bezoekSessieId/adres/:adresId/servicerapporten",
                        exact: true,
                        component: ServiceAdresServiceRapportenPage,
                        route: Route
                    },
                    {
                        key: "bijlagen",
                        path: "/bezoek/:bezoekSessieId/bijlagen",
                        exact: true,
                        component: ServiceAdresBijlagenPage,
                        route: Route
                    },
                    {
                        key: "adres",
                        path: "/bezoek/taken/:bezoekSessieId",
                        exact: true,
                        component: ServiceAdresTakenPage,
                        route: Route,

                        routes: [
                            {
                                key: "handtekening",
                                path: "/bezoek/taken/:bezoekSessieId/handtekening",
                                exact: true,
                                component: HandtekeningPage,
                                route: Route
                            },
                            {
                                key: "intern",
                                path: "/bezoek/taken/:bezoekSessieId/intern",
                                exact: true,
                                component: InternPage,
                                route: Route
                            },
                            {
                                key: "toestel",
                                path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId",
                                exact: true,
                                component: ToestelPage,
                                route: Route,
                                getParentRoutePath: (item: RouteItem, params: any) => generatePath(item.parent!.path, params) + "#toestellen",

                                routes: [
                                    {
                                        key: "toestel-stuklijst",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/stuklijst",
                                        exact: true,
                                        component: ToestelStuklijstPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-checkin",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/checkin",
                                        exact: true,
                                        component: ToestelCheckInPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-waterstand",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/waterstand",
                                        exact: true,
                                        component: WaterstandOpnemenPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-interventie",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/opdracht/interventie/:interventieId",
                                        exact: true,
                                        component: InterventiePage,
                                        route: Route
                                    },
                                    {
                                        key: "installatie-callback",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/opdracht/interventie/:interventieId/callback",
                                        exact: true,
                                        route: Route,
                                        component: () => {
                                            return <CallbackRoute targetType="interventie" targetIdExtractor={params => params.interventieId}/>;
                                        }
                                    },
                                    {
                                        key: "toestel-opdracht",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/opdracht/:opdrachtId",
                                        exact: true,
                                        component: ToestelOpdrachtPage,
                                        route: Route
                                    },
                                    {
                                        key: "scan-toestel",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/scan",
                                        exact: true,
                                        component: ScanToestelPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-foto",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/foto",
                                        exact: true,
                                        component: ToestelFotoPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-servicelog",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/servicelog",
                                        exact: true,
                                        component: ToestelServiceLogPage,
                                        route: Route
                                    },
                                    {
                                        key: "toestel-bijlagen",
                                        path: "/bezoek/taken/:bezoekSessieId/toestel/:toestelId/bijlagen",
                                        exact: true,
                                        component: ToestelBijlagenPage,
                                        route: Route
                                    }
                                ]
                            },
                            {
                                key: "opdracht",
                                path: "/bezoek/taken/:bezoekSessieId/opdracht/:opdrachtId",
                                exact: true,
                                component: OpdrachtPage,
                                nameComponent: OpdrachtPageName,
                                route: Route
                            },
                            {
                                key: "installatie",
                                path: "/bezoek/taken/:bezoekSessieId/opdracht/installatie/:installatieId",
                                exact: true,
                                component: InstallatiePage,
                                route: Route,

                                routes: [
                                    {
                                        key: "installatie-bijlagen",
                                        path: "/bezoek/taken/:bezoekSessieId/opdracht/installatie/:installatieId/dossier",
                                        exact: true,
                                        component: InstallatieDossierPage,
                                        route: Route
                                    }
                                ]
                            },
                            {
                                key: "installatie-callback",
                                path: "/bezoek/taken/:bezoekSessieId/opdracht/installatie/:installatieId/callback",
                                exact: true,
                                route: Route,
                                component: () => {
                                    return <CallbackRoute targetType="installatie" targetIdExtractor={params => params.installatieId}/>;
                                }
                            }
                        ]
                    }
                ]
            },
            {
                key: "bezoeksessies",
                path: "/bezoeksessies",
                exact: true,
                component: BezoeksessiesPage,
                route: Route
            },
            {
                key: "offlinebeheer",
                path: "/offlinebeheer",
                exact: true,
                component: OfflinebeheerPage,
                route: Route
            },
            {
                key: "local-events-debug",
                path: "/lokale-gebeurtenissen",
                exact: true,
                component: LokaleGebeurtenissenPage,
                route: Route
            },
            {
                key: "errors-debug",
                path: "/foutmeldingen",
                exact: true,
                component: FoutmeldingenPage,
                route: Route
            },
            {
                key: "over",
                path: "/over",
                exact: true,
                component: OverDezeApplicatePage,
                route: Route
            },
            {
                key: "documenten",
                path: "/documenten",
                exact: true,
                component: DocumentenPage,
                route: Route
            },
            {
                key: "zoek-toestel",
                path: "/zoek-toestel",
                exact: true,
                component: ZoekToestelPage,
                route: Route
            },
            {
                key: "shiften",
                path: "/shiften",
                exact: true,
                component: ShiftenPage,
                route: Route
            },
            {
                key: "planningoverzicht",
                path: "/overzicht",
                exact: true,
                component: PlanningOverzichtPage,
                route: Route
            }
        ]
    }
];

const processRoute = (route: RouteItem, parent?: RouteItem) => {
    let routes: RouteItem[] = [];

    route.parent = parent;

    if (route.routes) {
        routes.push(route);

        for (const item of route.routes) {
            routes = routes.concat(processRoute(item, route));
        }
    } else {
        routes = routes.concat(route);
    }

    return routes;
}

export const flatRoutes: RouteItem[] = [];

for (const route of routes) {
    flatRoutes.push(...processRoute(route));
}

