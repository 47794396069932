import React from "react";
import Icon from "@mdi/react";
import {mdiFaucetVariant, mdiSignalVariant} from "@mdi/js";

export const FaucetSignalIcon: React.FC = () => (
    <div style={{position: "relative", width: "100%", height: "100%", opacity: 0.6}}>
        <Icon path={mdiSignalVariant} size={2} style={{position: "absolute", left: 85, top: 0}}/>
        <Icon path={mdiFaucetVariant} size={5} style={{position: "absolute", left: 0, top: 30}}/>
    </div>
);
