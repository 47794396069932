import React from "react";
import {useToestel} from "../../../redux/slices/toestellen/hooks";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import Icon from "@mdi/react";
import {Assets} from "../../../assets/assets";
import {ToestelInterventie} from "../../../_generated/field-service-be-openapi";
import {InterventieOmschrijving} from "../Opdracht/InterventieOmschrijving";
import {ToestelLocatie} from "../../../components/toestel/ToestelLocatie";
import {ToestelInformatieRow} from "../../../components/toestel/ToestelInformatieRow";
import {useShowModal} from "../../../redux/slices/modal/hooks";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {useTranslation} from "../../../utilities/i18nUtils";

export interface GeplandWerkInterventieTaakProps {
    toestelId: string;
    interventie: ToestelInterventie;
}

export const GeplandWerkInterventieTaakCard: React.FC<GeplandWerkInterventieTaakProps> = (props) => {
    const {
        toestelId,
        interventie
    } = props;

    const toestel = useToestel(toestelId);

    const bijlagenAantal = toestel.bijlagen?.length;

    const showModal = useShowModal();

    const {t} = useTranslation("serviceadres");

    return (
        <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
            <MDBCardHeader><Icon path={Assets.orderTypes.levering}
                                 size={1}/> {t("GeplandWerkInterventieTaakCard.interventie", "Interventie {{nummer}}", {nummer: interventie.oorsprong.serviceOrderNr})}</MDBCardHeader>

            <MDBCardBody>
                <ToestelInformatieRow toestel={toestel}/>

                <MDBRow className="justify-content-around mb-4">
                    <MDBCol md="4">
                        <MDBBtn color="primary" outline size="lg" block onClick={() => showModal({
                            type: FieldServiceModal.TOESTEL_SERVICELOG_MODAL,
                            props: {toestel}
                        })}>{t("toestel:Titels.servicelog", "Servicelog")}</MDBBtn>
                    </MDBCol>
                    <MDBCol md="4">
                        <MDBBtn color="primary" outline size="lg" block onClick={() => showModal({
                            type: FieldServiceModal.TOESTEL_BIJLAGEN_MODAL,
                            props: {toestel}
                        })}>{t("toestel:Titels.bijlagen", "Bijlagen")} {!!bijlagenAantal && <MDBBadge pill>{bijlagenAantal}</MDBBadge>}</MDBBtn>
                    </MDBCol>
                    <MDBCol md="4">
                        <MDBBtn color="primary" outline size="lg" block onClick={() => showModal({
                            type: FieldServiceModal.TOESTEL_STUKLIJST_MODAL,
                            props: {toestel}
                        })}>{t("toestel:Titels.stuklijst", "Stuklijst")}</MDBBtn>
                    </MDBCol>
                </MDBRow>

                <MDBRow tag="dl">
                    <MDBCol tag="dt" sm="4">
                        {t("algemeen:Titels.omschrijving", "Omschrijving")}
                    </MDBCol>
                    <MDBCol tag="dd" sm="8">
                        <InterventieOmschrijving taakType={interventie.taakType}
                                                 omschrijving={interventie.omschrijving}/>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol size="12">
                        <strong>{t("algemeen:Titels.artikelen", "Artikelen")}</strong>

                        <MDBTable responsive>
                            <MDBTableHead>
                                <tr>
                                    <th scope="col">{t("algemeen:Titels.aantal", "Aantal")}</th>
                                    <th scope="col">{t("algemeen:Titels.artikelnr", "Artikelnr.")}</th>
                                    <th scope="col">{t("algemeen:Titels.omschrijving", "Omschrijving")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {!interventie?.meeTeGevenArtikels?.length && (
                                    <tr>
                                        <td colSpan={3}>{t("GeplandWerkInterventieTaakCard.geen-mee-te-geven-artikelen", "Geen mee te geven artikelen")}</td>
                                    </tr>
                                )}
                                {interventie?.meeTeGevenArtikels?.map(item => {
                                    return (
                                        <tr>
                                            <td>{item.aantal || 1} x</td>
                                            <td className="align-middle">
                                                <strong>{item.artikelNr}</strong>
                                            </td>
                                            <td className="align-middle">
                                                {item.artikelOmschrijving}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
