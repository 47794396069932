import React, {useMemo} from "react";
import {MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardText, MDBCol, MDBRow} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiInformationOutline, mdiPencilCircleOutline} from "@mdi/js";
import {useParams} from "react-router-dom";
import {GenericPage} from "../../../components/GenericPage";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {Adres} from "../../../components/Adres";
import {useToestel, useToestelCheckOut, useToestelInformatieAangepast} from "../../../redux/slices/toestellen/hooks";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {ToestelUitTeVoerenWerkWatermeterStandOpnameTypeEnum} from "../../../_generated/field-service-be-openapi";
import {ToestelInformatieAanpassenFormValues} from "../../../components/modals/ToestelInformatieAanpassenModal";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {ToestelIncheckenCard} from "./Taken/ToestelIncheckenCard";
import {ToestelInterventieCard} from "./Taken/ToestelInterventieCard";
import {ToestelOnderhoudCard} from "./Taken/ToestelOnderhoudCard";
import {ToestelWatermeterStandOpnameCard} from "./Taken/ToestelWatermeterStandOpnameCard";
import {ToestelInformatieRow} from "../../../components/toestel/ToestelInformatieRow";
import {ToestelLinksRow} from "../../../components/toestel/ToestelLinksRow";
import {useTranslation} from "../../../utilities/i18nUtils";
import {ToestelLocatie} from "../../../components/toestel/ToestelLocatie";
import {useGoBack} from "../../../routes/useGoBack";

export interface ToestelPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const ToestelPage: React.FC = () => {
    const {bezoekSessieId, toestelId} = useParams<ToestelPageParams>();

    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const toestelCheckOut = useToestelCheckOut();

    const toestel = useToestel(toestelId);
    const bezoekSessieToestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);

    const toestelIngecheckt = bezoekSessieToestel?.aanwezig || false;

    const toestelInformatieAangepast = useToestelInformatieAangepast();

    const goBack = useGoBack();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const interventies = useMemo(() => bezoekSessieToestel?.getInterventies() || [], [bezoekSessieToestel]);
    const onderhoud = bezoekSessieToestel?.getOnderhoud();

    const {t} = useTranslation("toestel");

    if (!bezoekSessie || !bezoekSessieToestel) {
        return null;
    }

    const onAanpassenClick = () => {
        showModal({
            type: FieldServiceModal.TOESTELINFORMATIE_AANPASSEN_MODAL,
            props: {
                toestel: bezoekSessieToestel,
                bezoekSessieId: bezoekSessieId,
                serviceAdresId: bezoekSessie?.serviceAdres?.id,
                onConfirm: (values: Partial<ToestelInformatieAanpassenFormValues>) => {
                    if ([ // Indien er op z'n minst één waarde veranderd is (onveranderde waarden zijn undefined)
                        values.serieNummer,
                        values.locatieBeschrijving,
                        values.nieuweToestelOpmerking,
                        values.gebouw,
                        values.verdieping,
                        values.lokaal
                    ].filter(Boolean).length > 0) {
                        toestelInformatieAangepast(bezoekSessie.id, toestelId, {
                            serieNummer: values.serieNummer,
                            serieNummerOrigineel: bezoekSessieToestel?.informatie?.serieNummer,

                            locatieBeschrijving: values.locatieBeschrijving,
                            locatieBeschrijvingOrigineel: bezoekSessieToestel?.informatie?.locatieBeschrijving,

                            gebouw: values.gebouw,
                            gebouwOrigineel: bezoekSessieToestel?.informatie?.gebouw,

                            verdieping: values.verdieping,
                            verdiepingOrigineel: bezoekSessieToestel?.informatie?.verdieping,

                            lokaal: values.lokaal,
                            lokaalOrigineel: bezoekSessieToestel?.informatie?.lokaalnummer,

                            opmerking: values.nieuweToestelOpmerking
                        });
                    }

                    hideModal();
                },
                onClose: () => hideModal()
            }
        })
    };

    const onBevestigToestel = () => {
        const checkOut = () => {
            toestelCheckOut(bezoekSessie.id, bezoekSessieToestel.informatie?.id!);
            goBack();
        };

        if (bezoekSessieToestel.alleVereisteTakenUitgevoerd) {
            checkOut();
        } else {
            showModal({
                type: FieldServiceModal.CONFIRM_MODAL,
                props: {
                    title: t("toestelPage.niet-alle-taken-zijn-uitgevoerd", "Niet alle taken zijn uitgevoerd"),
                    content: t("toestelPage.weet-u-zeker-dat-u-wenst-it-te-checken",
                        "Weet u zeker dat u wenst uit te checken zonder alle taken uit te voeren?"),

                    onConfirm: () => {
                        checkOut();
                    },
                    onClose: () => hideModal()
                }
            });
        }
    };

    const renderTaken = () => {
        return (
            <>
                <ToestelIncheckenCard toestelId={toestelId}
                                      bezoekSessieId={bezoekSessieId}
                                      toestelIngecheckt={toestelIngecheckt}
                />

                {interventies.map((interventie, idx) => {
                    const uitTeVoerenInterventie = bezoekSessieToestel?.uitTeVoerenWerk?.interventies?.find(item => item.id === interventie.id);

                    return (
                        <ToestelInterventieCard key={idx}
                                                toestelId={toestelId}
                                                bezoekSessieId={bezoekSessieId}
                                                toestelIngecheckt={toestelIngecheckt}
                                                interventieId={interventie.id}
                                                taakType={uitTeVoerenInterventie?.taakType}
                                                omschrijving={uitTeVoerenInterventie?.omschrijving || ""}
                                                bezoekSessieStatus={bezoekSessie?.status}
                                                uitgevoerd={interventie.uitgevoerd}
                        />
                    );
                })}

                {onderhoud && (
                    <ToestelOnderhoudCard onderhoud={onderhoud}
                                          toestelIngecheckt={toestelIngecheckt}
                                          toestelId={toestelId}
                                          bezoekSessieId={bezoekSessieId}
                                          bezoekSessieStatus={bezoekSessie?.status}
                    />
                )}

                {bezoekSessieToestel.uitTeVoerenWerk?.watermeterStandOpnameType !== ToestelUitTeVoerenWerkWatermeterStandOpnameTypeEnum.NietOndersteund && (
                    <ToestelWatermeterStandOpnameCard toestelIngecheckt={toestelIngecheckt}
                                                      bezoekSessieStatus={bezoekSessie.status}
                                                      bezoekSessieId={bezoekSessieId}
                                                      bezoekSessieToestel={bezoekSessieToestel}
                    />
                )}

                {bezoekSessieToestel?.aanwezig && (
                    <MDBRow className="mt-3 mb-3">
                        <MDBCol>
                            <BezoekMDBBtn block size="lg" onClick={onBevestigToestel}>
                                {t("ToestelPage.uitchecken-van-toestel", "Uitchecken van toestel")}
                            </BezoekMDBBtn>
                        </MDBCol>
                    </MDBRow>
                )}
            </>
        );
    };

    return (
        <GenericPage>
            <Header title={<ToestelLocatie toestel={bezoekSessieToestel.informatie} br={false}/>}
                    subtitle={<><strong>{serviceAdres?.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <div className="container-lg pt-3 flex-fill bg-white">
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader className="d-flex align-items-center justify-content-between">
                        <div>
                            <Icon path={mdiInformationOutline}
                                  size={1}/> {t("ToestelPage.toestelInformatie", "Toestelinformatie")}
                        </div>
                        <div>
                            <BezoekMDBBtn color="link" className="p-2" size="lg" onClick={onAanpassenClick}>
                                <Icon path={mdiPencilCircleOutline} size={1}/>
                            </BezoekMDBBtn>
                        </div>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <ToestelInformatieRow toestel={toestel || bezoekSessieToestel.informatie}/>
                        </MDBCardText>
                    </MDBCardBody>

                    <MDBCardFooter>
                        <ToestelLinksRow bezoekSessieId={bezoekSessieId} toestelId={toestelId} bijlagenAantal={bezoekSessieToestel.informatie?.bijlagen?.length}/>
                    </MDBCardFooter>
                </MDBCard>

                {renderTaken()}
            </div>
        </GenericPage>
    );
};
