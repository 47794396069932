export enum IotDeviceCallbackEventType {
    IOT_DEVICE_ONBOARDED = "IOT_DEVICE_ONBOARDED",
    IOT_DEVICE_ONBOARDING_FAILED = "IOT_DEVICE_ONBOARDING_FAILED",
    IOT_DEVICE_DETACHED = "IOT_DEVICE_DETACHED",
    IOT_DEVICE_CALIBRATION_CHANGED = "IOT_DEVICE_CALIBRATION_CHANGED"
}

export interface DeviceOption {
    optionCorrelationId: string;
    optionType: string; // ZIGBEE_PLUG_COOLER, ZIGBEE_PLUG_BOILER, VENTILATION_MODULE
    optionId: string;
}

export interface DeviceError {
    errorCode: string;
    errorDescription: string;
}

export interface IotDeviceOnboardedEvent {
    deviceId: string;
    iotPlatformName: string;
    iotSoftDeviceType: string;
    iotSoftDeviceId: string;
    deviceOptions: DeviceOption[];
    deviceErrors: DeviceError[];
    iotSoftwareDeviceAttachedToCustomerId: string;
    iotSoftwareDeviceAttachedToShippingAddressId: string;
    referrerName: string;
    referrerVersion: string;
    referrerEnvironment: string;
}

export interface IotDeviceOnboardingFailedEvent {
    deviceId: string;
    iotPlatformName: string;
    failedReason: string;
    referrerName: string;
    referrerVersion: string;
    referrerEnvironment: string;
}

export interface IotDeviceDetachedEvent {
    iotPlatformName: string;
    detachedIotSoftDeviceType: string;
    detachedIotSoftDeviceId: string;
    referrerName: string;
    referrerVersion: string;
    referrerEnvironment: string;
}

export interface IotDeviceCalibrationChangedEvent {
    deviceId: string;
    iotPlatformName: string;
    iotSoftDeviceType: string;
    iotSoftDeviceId: string;
    deviceOptions: DeviceOption[];
    deviceErrors: DeviceError[];
    referrerName: string;
    referrerVersion: string;
    referrerEnvironment: string;
}
