import {globalScope} from "./globalScope";
import {ReactNode} from "react";
import {UitTeVoerenWerkType} from "../workers/shared/snapshot/uitTeVoerenWerkType";
import {
    DagPlanningRestModelStatusEnum,
    GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum,
    ToestelInterventieTaakTypeEnum
} from "../_generated/field-service-be-openapi";
import {Translation} from "react-i18next";

export class Config {

    static BASE_URL = globalScope._env_.FIELD_SERVICE_BASE_URL!;
    static WEBSOCKET_URL = globalScope._env_.FIELD_SERVICE_WEBSOCKET_URL!;
    static ENVIRONMENT = globalScope._env_.FIELD_SERVICE_ENVIRONMENT!;

    static IOT_INSTALLER_URL = globalScope._env_.IOT_INSTALLER_URL!;

    static SENTRY_DSN = globalScope._env_.SENTRY_DSN;
    static SENTRY_TRACES_SAMPLE_RATE = globalScope._env_.SENTRY_TRACES_SAMPLE_RATE || 0;

    static GOOGLE_MAPS_KEY = globalScope._env_.FIELD_SERVICE_GOOGLE_MAPS_KEY!;
    static VIEWPORT_BORDER_COLOR = globalScope._env_.VIEWPORT_BORDER_COLOR!;

    static OAUTH_CLIENT_ID = globalScope._env_.OAUTH_CLIENT_ID;
    static OAUTH_CLIENT_SCOPES = globalScope._env_.OAUTH_CLIENT_SCOPES || [];
    static OAUTH_AUTHORITY = globalScope._env_.OAUTH_AUTHORITY || [];

    static PERIOD_SYNC_INTERVAL = globalScope._env_.PERIOD_SYNC_INTERVAL || 12 * 60 * 60 * 1000;

    static LOGGING_ENABLED = globalScope._env_.LOGGING_ENABLED;

    static VERSION = process.env.REACT_APP_VERSION;

    static FETCH_API = globalScope.fetch.bind(globalScope);

    static IS_BROWSER = typeof window !== "undefined";
    static IS_SERVICE_WORKER = typeof window === "undefined";

    static SNAPSHOT_EVENT_AMOUNT_INTERVAL = 5;
    static DAG_PLANNING_VERSIE_CHECK_INTERVAL_MS = globalScope._env_.VIEWPORT_BORDER_COLOR || 60_000;

    static ERRORS_TO_SKIP = [
        "QuotaExceededError",
        "NotAllowedError",
        "no-response",
        "Failed to fetch"
    ];

    static BACKGROUND_SYNC_TAG_NAME = "SyncBezoekSessieEvent2Server";

    static DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
    };

    static labels: Record<string, ReactNode> = {
        [UitTeVoerenWerkType.TOESTEL_INTERVENTIE]:
            <Translation>{t => t("algemeen:Titels.interventie", "Interventie")}</Translation>,
        [UitTeVoerenWerkType.TOESTEL_ONDERHOUD]:
            <Translation>{t => t("algemeen:Titels.onderhoud", "Onderhoud")}</Translation>,
        [UitTeVoerenWerkType.WATERMETER_STAND_OPNAME]:
            <Translation>{t => t("algemeen:Titels.watermeterstand-opname", "Watermeterstandopname")}</Translation>,
        [UitTeVoerenWerkType.ACCESSOIRE_LEVERING]:
            <Translation>{t => t("algemeen:Titels.accessoirelevering", "Accessoirelevering")}</Translation>,
        [UitTeVoerenWerkType.HANDTEKENING]:
            <Translation>{t => t("algemeen:Titels.handtekening", "Handtekening")}</Translation>,
        [UitTeVoerenWerkType.INSTALLATIE]:
            <Translation>{t => t("algemeen:Titels.installatie", "Installatie")}</Translation>,
        [UitTeVoerenWerkType.CO2_LEVERING]:
            <Translation>{t => t("algemeen:Titels.co2-levering", "CO₂-levering")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Advies]:
            <Translation>{t => t("algemeen:Titels.advies", "Advies")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Herstelling]:
            <Translation>{t => t("algemeen:Titels.herstelling", "Herstelling")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Upgrade]:
            <Translation>{t => t("algemeen:Titels.upgrade", "Upgrade")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Verplaatsing]:
            <Translation>{t => t("algemeen:Titels.verplaatsing", "Verplaatsing")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Verhuis]:
            <Translation>{t => t("algemeen:Titels.verhuis", "Verhuis")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Ophaling]:
            <Translation>{t => t("algemeen:Titels.ophaling", "Ophaling")}</Translation>,
        [ToestelInterventieTaakTypeEnum.StaalnameLaboOnderzoek]:
            <Translation>{t => t("algemeen:Titels.staalname-labo-onderzoek", "Staalname labo-onderzoek")}</Translation>,
        [ToestelInterventieTaakTypeEnum.Opstart]:
            <Translation>{t => t("algemeen:Titels.opstart", "Opstart")}</Translation>,
        [GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.AndereSoortFlessenGeleverd]:
            <Translation>{t => t("co2-bestelling:OnvolledigeLeveringReden.andere-soort-flessen-geleverd",
                "Andere soort flessen geleverd")}</Translation>,
        [GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.KlantAkkoord]:
            <Translation>{t => t("co2-bestelling:OnvolledigeLeveringReden.klant-akkoord",
                "Klant akkoord")}</Translation>,
        [GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.TeWeinigFlessenBeschikbaar]:
            <Translation>{t => t("co2-bestelling:OnvolledigeLeveringReden.technieker-heeft-te-weinig-flessen-beschikbaar",
                "Technieker heeft te weinig flessen beschikbaar")}</Translation>,
        [GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.VraagKlant]:
            <Translation>{t => t("co2-bestelling:OnvolledigeLeveringReden.vraag-klant",
                "Vraag klant")}</Translation>,
        [GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum.KlantKanTeWeinigCo2FlessenOmruilen]:
            <Translation>{t => t("co2-bestelling:OnvolledigeLeveringReden.klant-kan-te-weinig-flessen-omruilen",
                "Klant kan te weinig CO₂-flessen omruilen")}</Translation>,

    };

    static categoryLabels: Record<string, Record<string, ReactNode>> = {
        planningLeegTekst: {
            [DagPlanningRestModelStatusEnum.Ontwerp]: <Translation>{t => t("planning:Titels.planning-leeg.ontwerp", "Je planning is nog niet vastgelegd")}</Translation>,
            [DagPlanningRestModelStatusEnum.Gearchiveerd]: <Translation>{t => t("planning:Titels.planning-leeg.gearchiveerd", "Deze planning is gearchiveerd")}</Translation>,
            [DagPlanningRestModelStatusEnum.NietsPlannen]: <Translation>{t => t("planning:Titels.planning-leeg.niets-plannen", "Deze dag staat aangeduid als 'niets plannen'")}</Translation>
        }
    };

}
