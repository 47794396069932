import React, {useMemo} from "react";
import {MDBCol, MDBRow, MDBTypography} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiOrderBoolAscendingVariant} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useParams} from "react-router-dom";
import {Adres} from "../../../components/Adres";
import {useServiceAdresCo2LeveringEvent} from "../../../redux/slices/serviceAdressen/hooks";
import {useBezoekSessieById} from "../../../redux/slices/bezoeksessie/hooks";
import {Form, Formik} from "formik";
import {
    BezoekSessieEventPayload,
    GeleverdeCo2Artikels,
    GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum,
    ServiceAdresCo2ArtikelRestModel
} from "../../../_generated/field-service-be-openapi";
import {useMe} from "../../../redux/slices/technicus/hooks";
import * as Yup from "yup";
import {AnySchema} from "yup";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {Co2LeveringBesteldCard} from "../../../components/opdracht/co2/Co2LeveringBesteldCard";
import {Co2LeveringForm} from "../../../components/opdracht/co2/Co2LeveringForm";
import {useHideModal, useShowModal} from "../../../redux/slices/modal/hooks";
import {FieldServiceModal} from "../../../components/FieldServiceModal";
import {Co2LeveringUtils} from "../../../utilities/co2LeveringUtils";
import {
    Co2LeveringAfwijkendFormValues,
    Co2LeveringAfwijkendModalProps
} from "../../../components/modals/Co2LeveringAfwijkendModal";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {Co2GebruiksrechtWaarschuwing} from "../../../components/co2Levering/Co2GebruiksrechtWaarschuwing";
import {useKlant} from "../../../redux/slices/klant/hooks";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";
import {useGoBack} from "../../../routes/useGoBack";

export interface Co2LeveringGeleverdArtikel {
    geleverdAantal: number;
    geleverdOmruilAantal: number;
    onvolledigeLeveringReden?: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum;
}

export interface Co2LeveringFormValues {
    /**
     * Key: artikel-id
     */
    bestelling: Record<string, Co2LeveringGeleverdArtikel>;
}

export interface Co2LeveringPageParams {
    bezoekSessieId: string;
}

export const Co2LeveringPage: React.FC = () => {
    const goBack = useGoBack();

    const showModal = useShowModal();
    const hideModal = useHideModal();

    const {bezoekSessieId} = useParams<Co2LeveringPageParams>();
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const serviceAdres = bezoekSessie?.serviceAdres;
    const klant = useKlant(serviceAdres?.klantId);

    const technicus = useMe();
    const leverCo2 = useServiceAdresCo2LeveringEvent();

    const maximumAantalCO2Levering = 100;

    const bestellingen = bezoekSessie?.uitTeVoerenWerk?.co2Leveringen;

    const {t} = useTranslation("serviceadres");

    const onConfirm = (values: Co2LeveringFormValues, afwijkValues?: Co2LeveringAfwijkendFormValues) => {
        if (!bezoekSessie || !technicus) {
            return;
        }

        let leveringen: GeleverdeCo2Artikels[] = [];

        if (values.bestelling) {
            leveringen = Object.entries(values.bestelling)
                .filter(([artikelId, geleverdArtikel]) => {
                    const artikel = serviceAdres?.co2Artikelen?.find(item => item.id === artikelId);

                    if (artikel?.hervulbaar) {
                        return !(geleverdArtikel.geleverdAantal as any === "" && geleverdArtikel.geleverdOmruilAantal as any === "");
                    }

                    return geleverdArtikel.geleverdAantal as any !== "";
                })
                .map(([artikelId, geleverdArtikel]) => ({
                    artikelId,
                    aantal: geleverdArtikel.geleverdAantal,
                    aantalRetour: geleverdArtikel.geleverdOmruilAantal,
                    onvolledigeLeveringReden: afwijkValues?.artikel?.[artikelId]?.reden
                }));
        }

        const event: BezoekSessieEventPayload = {
            _type: "CO2_GELEVERD",
            technicusId: technicus.id,
            leveringen,
            toestemmingKlantAfwijkendeBestelling: afwijkValues?.toestemmingKlantAfwijkendeBestelling || false
        };

        leverCo2(bezoekSessie.id, event);

        goBack();
    };

    const onFormSubmit = (values: Co2LeveringFormValues) => {
        if (!bezoekSessie || !technicus) {
            return;
        }

        const gecombineerdeLeveringen = Co2LeveringUtils.combineer(bestellingen || [], values.bestelling, serviceAdres?.co2Artikelen);
        const afwijkendeCo2Leveringen = Object.values(gecombineerdeLeveringen)
            .some(item => values.bestelling[item.id].geleverdAantal < item.gevraagdAantal + item.gevraagdExtraAantal);

        if (afwijkendeCo2Leveringen) {
            showModal({
                type: FieldServiceModal.CO2_LEVERING_AFWIJKEND_MODAL,
                props: {
                    co2Artikelen: serviceAdres?.co2Artikelen,
                    leveringen: values.bestelling,
                    bestellingen,
                    bestellingTerPlaatseToegestaan: bezoekSessie?.uitTeVoerenWerk?.co2BestellingTerPlaatseToegestaan,

                    onConfirm: (afwijkValues) => onConfirm(values, afwijkValues),
                    onClose: () => hideModal()
                } as Co2LeveringAfwijkendModalProps
            });
        } else {
            onConfirm(values);
        }
    };

    const bestelling: Record<string, Co2LeveringGeleverdArtikel> = useMemo(() => {
        const bestelling: Record<string, Co2LeveringGeleverdArtikel> = {};

        for (const artikel of Object.values(bezoekSessie?.co2Levering?.leveringen || {})) {
            if (bestelling[artikel.artikelId]) {
                bestelling[artikel.artikelId].geleverdAantal += artikel.aantalGeleverd || 0;
            } else {
                bestelling[artikel.artikelId] = {
                    geleverdAantal: artikel.aantalGeleverd || 0,
                    geleverdOmruilAantal: artikel.aantalOmruilGeleverd || 0
                };
            }

            bestelling[artikel.artikelId].onvolledigeLeveringReden = artikel.onvolledigeLeveringReden;
        }

        return bestelling;
    }, [bezoekSessie?.co2Levering?.leveringen]);

    const initialValues: Co2LeveringFormValues = useMemo(() => ({
        bestelling,
        toestemmingKlantAfwijkendeBestelling: bezoekSessie?.co2Levering?.toestemmingKlantAfwijkendeBestelling
    }), [bestelling, bezoekSessie?.co2Levering?.toestemmingKlantAfwijkendeBestelling]);

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        const bestelling: Record<string, AnySchema> = {};

        const besteldeArtikelIds = bestellingen?.map(item => item.artikelId) || [];

        const testGeleverdAantalVerplicht = (artikel?: ServiceAdresCo2ArtikelRestModel) => function (this: any, value: any) {
            const {geleverdOmruilAantal} = this.parent;

            const geleverdAantalDefined = value !== undefined && value !== null;
            const geleverdOmruilAantalDefined = geleverdOmruilAantal !== undefined && geleverdOmruilAantal !== null;

            if (!geleverdAantalDefined && geleverdOmruilAantalDefined && artikel?.hervulbaar) {
                return false;
            }

            return true;
        };

        const testGeleverdOmruilAantalVerplicht = (artikel?: ServiceAdresCo2ArtikelRestModel) => function (this: any, value: any) {
            if (!artikel?.hervulbaar) {
                return true;
            }

            const {geleverdAantal} = this.parent;

            const geleverdAantalDefined = geleverdAantal !== undefined && geleverdAantal !== null;
            const geleverdOmruilAantalDefined = value !== undefined && value !== null;
            if (geleverdAantalDefined && !geleverdOmruilAantalDefined) {
                return false;
            }

            return true;
        };

        const aantalGeleverdIsVerplichtFoutmelding = t("CO2LeveringPage.aantal-geleverd-is-verplicht", "Aantal geleverd is verplicht");
        const negatiefAantalFoutmelding = t("CO2LeveringPage.negatief-aantal", "Negatief aantal");
        const maximaalAantalFoutmelding = t("CO2LeveringPage.maximaal-aantal", "Maximaal aantal is 100");
        const aantalRetourIsVerplichtFoutmelding = t("CO2LeveringPage.aantal-retour-is-verplicht", "Aantal retour is verplicht")

        for (const key of besteldeArtikelIds) {
            const artikel = serviceAdres?.co2Artikelen?.find(item => item.id === key);

            const bestellingShape = Yup.object({
                geleverdAantal: Yup.number()
                    .required(aantalGeleverdIsVerplichtFoutmelding)
                    .min(0, negatiefAantalFoutmelding)
                    .max(maximumAantalCO2Levering, maximaalAantalFoutmelding)
                    .test("verplicht-indien-geleverd-omruil-aantal-ingevuld", aantalGeleverdIsVerplichtFoutmelding, testGeleverdAantalVerplicht(artikel)),
                geleverdOmruilAantal: Yup.number()
                    .test("geleverd-omruil-aantal-verplicht-bij-hervulbaar", aantalRetourIsVerplichtFoutmelding, function (value) {
                        if (artikel?.hervulbaar) {
                            return value != null;
                        }

                        return true;
                    })
                    .min(0, negatiefAantalFoutmelding)
                    .max(maximumAantalCO2Levering, maximaalAantalFoutmelding)
                    .test("verplicht-indien-geleverd-aantal-ingevuld", aantalRetourIsVerplichtFoutmelding, testGeleverdOmruilAantalVerplicht(artikel))
            });

            bestelling[key] = bestellingShape;
        }

        const bestellingKeys = Object.keys(obj.bestelling || {}).filter(item => !besteldeArtikelIds.includes(item));

        for (const key of bestellingKeys) {
            const artikel = serviceAdres?.co2Artikelen?.find(item => item.id === key);

            const bestellingShape = Yup.object({
                geleverdAantal: Yup.number()
                    .min(0, negatiefAantalFoutmelding)
                    .max(maximumAantalCO2Levering, maximaalAantalFoutmelding)
                    .test("verplicht-indien-geleverd-omruil-aantal-ingevuld", aantalGeleverdIsVerplichtFoutmelding, testGeleverdAantalVerplicht(artikel)),
                geleverdOmruilAantal: Yup.number()
                    .min(0, negatiefAantalFoutmelding)
                    .max(100, "Maximaal aantal is 100")
                    .test("verplicht-indien-geleverd-aantal-ingevuld", aantalRetourIsVerplichtFoutmelding, testGeleverdOmruilAantalVerplicht(artikel))
            });

            bestelling[key] = bestellingShape;
        }

        return Yup.object({
            bestelling: Yup.object(bestelling)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [serviceAdres?.co2Artikelen]);

    if (!bezoekSessie || !bezoekSessie.uitTeVoerenWerk) {
        throw new Error("Bezoeksessie niet gevonden");
    }
    if (!serviceAdres) {
        throw new Error("Serviceadres niet gevonden");
    }

    return (
        <GenericPage>
            <Header title={t("CO2LeveringPage.co2-levering", "CO₂-levering")}
                    subtitle={<><strong>{serviceAdres.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <Formik<Co2LeveringFormValues> initialValues={initialValues} validationSchema={validationSchema}
                                               onSubmit={onFormSubmit}>
                    {
                        ({values, dirty, isSubmitting}) => {
                            const {
                                meerOmruilDanGevraagd,
                                meerExtraDanGevraagd,
                                legeFlessenZonderOmruil,
                                gebruiksrechtZonderAkkoord,
                                extraGeleverdZonderToestemming,

                                totaalAantalGebruiksrechtZonderAkkoord
                            } = Co2LeveringUtils.valideer(bestellingen || [], values.bestelling, serviceAdres?.co2Artikelen, bezoekSessie.uitTeVoerenWerk!.co2BestellingTerPlaatseToegestaan);

                            const bestellingenTerPlaatse = Object.values(bezoekSessie?.co2Levering?.leveringen || {}).filter((item) => item.terPlaatse) || [];

                            return (
                                <Form autoComplete="off">
                                    <OnbewaardeGegevensMelding draftValues={bezoekSessie?.co2Levering?.draftValues}/>
                                    <SaveDraftFormik formId={`co2/${bezoekSessieId}`} bezoekSessieId={bezoekSessieId}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <Co2LeveringBesteldCard bestellingen={bestellingen}
                                                            bestellingenTerPlaatse={bestellingenTerPlaatse}
                                                            co2Artikelen={serviceAdres?.co2Artikelen || []}
                                    />

                                    {(meerExtraDanGevraagd || meerOmruilDanGevraagd) && (
                                        <MDBCol size="12" className="mt-3">
                                            <MDBTypography note noteColor="info">
                                                <strong>{t("CO2LeveringPage.opgelet", "Opgelet:")}</strong>{" "}
                                                {meerExtraDanGevraagd &&
                                                    <span>{t("CO2LeveringPage.je-levert-meer-flessen-dan-besteld", "Je levert meer flessen dan besteld.")}</span>}
                                                {meerOmruilDanGevraagd &&
                                                    <span>{t("CO2LeveringPage.je-ruilt-meer-flessen-om-dan-besteld", "Je ruilt meer flessen om dan besteld.")}</span>}
                                            </MDBTypography>
                                        </MDBCol>
                                    )}

                                    {legeFlessenZonderOmruil && (
                                        <MDBCol size="12" className="mt-3">
                                            <MDBTypography note noteColor="danger">
                                                <strong>
                                                    {t("CO2LEveringPage.fout", "Fout:")}{" "}
                                                </strong>
                                                {t("CO2LeveringPage.het-is-niet-toegelaten-om-lege-flessen-retour-te-nemen-zonder-deze-om-te-ruilen",
                                                    "Het is niet toegelaten om lege flessen retour te nemen zonder deze om te ruilen.")}
                                            </MDBTypography>
                                        </MDBCol>
                                    )}

                                    {gebruiksrechtZonderAkkoord && (
                                        <MDBCol size="12" className="mb-3">
                                            <Co2GebruiksrechtWaarschuwing extraCo2FlesBeleid={klant?.extraCo2FlesBeleid}
                                                                          aantal={totaalAantalGebruiksrechtZonderAkkoord}/>
                                        </MDBCol>
                                    )}

                                    {extraGeleverdZonderToestemming && (
                                        <MDBCol size="12" className="mt-3">
                                            <MDBTypography note noteColor="danger">
                                                <strong>{t("CO2LeveringPage.fout", "Fout:")}{" "}</strong>
                                                {t("CO2LeveringPage.voor-deze-klant-is-het-niet-toegelaten-meer-te-leveren-dan-op-voorhand-besteld",
                                                    "Voor deze klant is het niet toegelaten meer te leveren dan op voorhand besteld ('bestelling ter plaatse') omdat een extern documentnummer verplicht is.")}
                                            </MDBTypography>
                                        </MDBCol>
                                    )}

                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol className="d-flex align-items-center">
                                            <h5><Icon path={mdiOrderBoolAscendingVariant} size={1}
                                                      className="me-2"/> Levering</h5>
                                        </MDBCol>
                                    </MDBRow>
                                    <hr/>

                                    <Co2LeveringForm co2Artikelen={serviceAdres?.co2Artikelen || []}
                                                     bestellingen={bestellingen}/>

                                    <MDBRow className="mt-3">
                                        <MDBCol className="mt-2 mb-3">
                                            <BezoekMDBBtn block size="lg" type="submit">
                                                {t("CO2BestellingLeveringPage.bewaren", "Bewaren")}
                                            </BezoekMDBBtn>
                                        </MDBCol>
                                    </MDBRow>

                                </Form>
                            );
                        }
                    }
                </Formik>
            </ContentContainer>
        </GenericPage>
    );
};
