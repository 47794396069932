import React, {useEffect, useRef} from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {useCallbackOntvangen} from "../redux/slices/bezoeksessie/hooks";

export interface CallbackRouteProps {
    targetType: string;
    targetIdExtractor: (params: any) => string;
}

export const CallbackRoute: React.FC<CallbackRouteProps> = (props) => {
    const {targetType, targetIdExtractor} = props;

    const location = useLocation();
    const routeParams = useParams<{bezoekSessieId: string}>();

    const callbackGeregistreerd = useRef(false);
    const callbackOntvangen = useCallbackOntvangen();

    const search = location.search;
    const params = new URLSearchParams(search);

    const bezoekSessieId = routeParams.bezoekSessieId;
    const eventType = params.get("eventType");
    const payload = params.get("payload") || "";

    const redirectToUrl = location.pathname.replace("/callback", "");

    useEffect(() => {
        if (!eventType) {
            return;
        }

        if (!callbackGeregistreerd.current) {
            const targetId = targetIdExtractor(routeParams);

            callbackOntvangen(bezoekSessieId, targetType, redirectToUrl, targetId, eventType, payload);
            callbackGeregistreerd.current = true;
        }
    }, [callbackGeregistreerd, bezoekSessieId, eventType, payload, callbackOntvangen]);

    if (!bezoekSessieId || !eventType) {
        return <Redirect to="/"/>;
    }

    if (callbackGeregistreerd) {
        return <Redirect to={redirectToUrl}/>;
    }

    return null;
};
