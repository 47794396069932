import React from "react";
import {ToestelIotInitialCard} from "./ToestelIotInitialCard";
import {BezoekSessie, BezoekSessieToestelIot} from "../../workers/shared/snapshot/bezoekSessieState";
import {ToestelIotActivatedCard} from "./ToestelIotActivatedCard";
import {ToestelIotFailedCard} from "./ToestelIotFailedCard";
import {IotUtils} from "../../utilities/iot/IotUtils";
import {useFollowLinkToExternalUrlEvent} from "../../redux/slices/bezoeksessie/hooks";
import {IotDeviceCallbackEventType} from "../../utilities/iot/types";

export interface ToestelIotCardProps {
    bezoekSessie: BezoekSessie;
    iot?: BezoekSessieToestelIot;
}

export const ToestelIotCard: React.FC<ToestelIotCardProps> = (props) => {
    const {bezoekSessie, iot} = props;

    const followLinkToExternalUrlEvent = useFollowLinkToExternalUrlEvent();

    const serviceAdres = bezoekSessie.serviceAdres;

    if (!iot?.enabled || !serviceAdres) {
        return null;
    }

    const returnUrl = `${window.location.href}/callback`;

    const onAanpassenClick = () => {
        if (!iot.informatie?.softDeviceId) {
            return;
        }

        const editUrl = IotUtils.buildEditUrl(iot.informatie.softDeviceId, returnUrl);
        followLinkToExternalUrlEvent(bezoekSessie.id, window.location.href, editUrl);

        window.location.href = editUrl;
    };

    const onVerwijderenClick = () => {
        if (!iot.informatie?.softDeviceId) {
            return;
        }

        const detachUrl = IotUtils.buildDetachDeviceUrl(iot.informatie.softDeviceId, returnUrl);
        followLinkToExternalUrlEvent(bezoekSessie.id, window.location.href, detachUrl);

        window.location.href = detachUrl;
    };

    const onActiverenClick = () => {
        const onboardUrl = IotUtils.buildOnboardNewDeviceUrl(serviceAdres.klantId, serviceAdres.id, [], returnUrl);
        followLinkToExternalUrlEvent(bezoekSessie.id, window.location.href, onboardUrl);

        window.location.href = onboardUrl;
    };

    switch (iot.status) {
        case IotDeviceCallbackEventType.IOT_DEVICE_DETACHED:
            return (
                <ToestelIotInitialCard onActiverenClick={onActiverenClick}/>
            );
        case IotDeviceCallbackEventType.IOT_DEVICE_ONBOARDED:
            return (
                <ToestelIotActivatedCard iot={iot} onAanpassenClick={onAanpassenClick}
                                         onVerwijderenClick={onVerwijderenClick}/>
            );
        case IotDeviceCallbackEventType.IOT_DEVICE_ONBOARDING_FAILED:
            return (
                <ToestelIotFailedCard iot={iot} onActiverenClick={onActiverenClick}/>
            );
    }

    return null;
};
