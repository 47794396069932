import {createSliceSaga, SagaType} from "redux-toolkit-saga";
import {call, put, select} from "redux-saga/effects";
import {browser2ServiceWorkerChannel} from "../../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {selectMe} from "../technicus/slice";
import {EventMessagePayload} from "../../../workers/shared/channels/eventMessage";
import {RootState} from "../../store";
import {ReedsIngechecktOpServiceAdresError} from "../serviceAdressen/errors";
import {GpsLocatieGelogdOorsprongEnum} from "../../../_generated/field-service-be-openapi";
import {bezoekSessieApi} from "../../../utilities/api";
import {GpsUtils} from "../../../utilities/gpsUtils";
import {Logger} from "../../../utilities/logger";
import {statusSaga} from "../status/saga";
import {RequestUtils} from "../../../utilities/requestUtils";
import {WorkerMessageFactory} from "../../../workers/shared/workerMessageFactory";

const logger = Logger.create("BezoeksessieSaga");

export const bezoeksessieSaga = createSliceSaga({
    name: "bezoeksessieSaga",
    sagaType: SagaType.TakeLatest,
    caseSagas: {
        * startBezoeksessieEnOnderweg(action) {
            const technicusId = selectMe((yield select()) as any)?.id;
            const {
                serviceAdresId,
                bezoekSessieId,
                bezoekId,
                uitTeVoerenWerk,
                datum,
                planningVersie,
                planningVersieTijdstip,
                callback
            } = action.payload;

            const isOnderwegOfAanwezig = yield select((state: RootState) => {
                for (const bezoekSessie of Object.values(state.bezoeksessies.bezoekSessies)) {
                    if (bezoekSessie.aanwezig || bezoekSessie.onderweg) {
                        return true;
                    }
                }

                return false;
            });

            if (isOnderwegOfAanwezig) {
                if (callback) {
                    yield call(callback, new ReedsIngechecktOpServiceAdresError());
                }

                return;
            }

            GpsUtils.getLocation()
                .then((location) => {
                    logger.debug("Got location (StartBezoekSessieEnOnderweg)", location);

                    browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createBrowserBezoekSessieEventMessage({
                        event: {
                            _type: "GPS_LOCATIE_GELOGD",
                            latitude: location.coords.latitude,
                            longitude: location.coords.longitude,
                            accuracy: location.coords.accuracy,
                            oorsprong: GpsLocatieGelogdOorsprongEnum.Onderweg,
                            technicusId
                        },
                        bezoekSessieId,
                        technicusId
                    }));
                })
                .catch(error => logger.error("An error occurred getting current location (StartBezoekSessieEnOnderweg)", error));

            const bezoekSessieGestartPayload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "BEZOEK_SESSIE_GESTART",
                    datum,
                    technicusIds: [technicusId] as any,
                    serviceAdresId,
                    bezoekId,
                    uitTeVoerenWerk,
                    planningVersie, planningVersieTijdstip
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(bezoekSessieGestartPayload));

            const onderwegPayload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "ONDERWEG_NAAR_ADRES",
                    technicusId
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(onderwegPayload));

            if (callback) {
                yield call(callback, undefined, bezoekSessieId);
            }
        },
        * startBezoeksessie(action) {
            const technicusId = selectMe((yield select()) as any)?.id;
            const {
                serviceAdresId,
                bezoekSessieId,
                uitTeVoerenWerk,
                bezoekId,
                datum,
                planningVersie,
                planningVersieTijdstip,
                callback
            } = action.payload;

            const payload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "BEZOEK_SESSIE_GESTART",
                    datum,
                    technicusIds: [technicusId] as any,
                    serviceAdresId,
                    bezoekId,
                    uitTeVoerenWerk,
                    planningVersie,
                    planningVersieTijdstip
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));

            if (callback) {
                yield call(callback);
            }
        },
        * beeindigBezoeksessie(action) {
            const {bezoekSessieId} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const payload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "BEZOEK_SESSIE_GESLOTEN"
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * herlaadBezoekUitTeVoerenWerk(action) {
            const {bezoekSessieId} = action.payload;

            const instanceId = (yield select(state => state.status.instanceId)) as unknown as string;

            try {
                yield call(bezoekSessieApi.herlaadBezoekSessieUitTeVoerenWerkCommand.bind(bezoekSessieApi), {
                    bezoekSessieId,
                    instanceId
                });
            } catch (errorResponse) {
                const error = yield RequestUtils.fetchResponseToError(errorResponse as Response);
                yield put(statusSaga.actions.logError({error}));
            }
        },
        * sluitBezoekSessie(action) {
            const {bezoekSessieId} = action.payload;

            const instanceId = (yield select(state => state.status.instanceId)) as unknown as string;

            try {
                yield call(bezoekSessieApi.sluitBezoekSessieCommand.bind(bezoekSessieApi), {
                    bezoekSessieId,
                    instanceId
                });
            } catch (errorResponse) {
                const error = yield RequestUtils.fetchResponseToError(errorResponse as Response);
                yield put(statusSaga.actions.logError({error}));
            }
        },
        * voegTechnicusToe(action) {
            const {bezoekSessieId, technicusId} = action.payload;

            const instanceId = (yield select(state => state.status.instanceId)) as unknown as string;

            try {
                yield call(bezoekSessieApi.voegTechnicusToeAanBezoekSessieCommand.bind(bezoekSessieApi), {
                    bezoekSessieId,
                    technicusId,
                    instanceId
                });
            } catch (errorResponse) {
                const error = yield RequestUtils.fetchResponseToError(errorResponse as Response);
                yield put(statusSaga.actions.logError({error}));
            }
        },
        * verwijderTechnicus(action) {
            const {bezoekSessieId, technicusId} = action.payload;

            const instanceId = (yield select(state => state.status.instanceId)) as unknown as string;

            try {
                yield call(bezoekSessieApi.verwijderTechnicusVanBezoekSessieCommand.bind(bezoekSessieApi), {
                    bezoekSessieId,
                    technicusId,
                    instanceId
                });
            } catch (errorResponse) {
                const error = yield RequestUtils.fetchResponseToError(errorResponse as Response);
                yield put(statusSaga.actions.logError({error}));
            }
        },
        * deviceInfoGelogd(action) {
            const {bezoekSessieId} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;
            const instanceId = (yield select(state => state.status.instanceId)) as unknown as string;
            const batteryPercentage = (yield select(state => state.status.batteryPercentage)) as unknown as number;
            const userAgent = navigator.userAgent;

            const isReedsGelogd = (yield select((state: RootState) => {
                return state.bezoeksessies.bezoekSessies?.[bezoekSessieId]?.deviceInfoGelogdPerInstanceId?.[instanceId] || false;
            })) as unknown as boolean;

            if (isReedsGelogd) {
                return;
            }

            const payload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "DEVICE_INFO_GELOGD",
                    userAgent,
                    batteryPercentage,
                    technicusId,
                    instanceId
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(payload));
        },
        * callbackOntvangen(action) {
            const {bezoekSessieId, eventType, payload, targetId, targetType, targetUrl} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            let decodedPayload: object | undefined = undefined;
            try {
                decodedPayload = JSON.parse(atob(payload)); // Deprecated notice is valid for Node.js, not browser environments
            } catch (ignore) {
            }

            const eventMessagePayload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "CALLBACK_ONTVANGEN_EVENT",
                    technicusId,
                    targetType,
                    targetUrl,
                    targetId,
                    callbackEventType: eventType,
                    encodedPayload: payload,
                    decodedPayload
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(eventMessagePayload));
        },
        * followLinkToExternalUrlEvent(action) {
            const {bezoekSessieId, huidigePaginaUrl, navigeerNaarUrl} = action.payload;

            const technicusId = selectMe((yield select()) as any)?.id;

            const eventMessagePayload: EventMessagePayload = {
                bezoekSessieId,
                technicusId,
                event: {
                    _type: "FOLLOW_LINK_TO_EXTERNAL_URL_EVENT",
                    technicusId,
                    huidigePaginaUrl,
                    navigeerNaarUrl
                }
            };

            yield call(browser2ServiceWorkerChannel.publish, WorkerMessageFactory.createBrowserBezoekSessieEventMessage(eventMessagePayload));
        }
    }
});
