import React from "react";
import {MDBAlert, MDBBtn, MDBCol, MDBRow} from "../../mdb-react-ui-kit";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";
import {FaucetSignalIcon} from "./FaucetSignalIcon";
import {BezoekSessieToestelIot} from "../../workers/shared/snapshot/bezoekSessieState";
import {useTranslation} from "../../utilities/i18nUtils";
import {Trans} from "react-i18next";

export interface ToestelIotFailedCardProps {
    iot?: BezoekSessieToestelIot;

    onActiverenClick(): void;
}

export const ToestelIotFailedCard: React.FC<ToestelIotFailedCardProps> = (props) => {
    const {iot, onActiverenClick} = props;

    const {t} = useTranslation("toestel");

    return (
        <MDBAlert color="danger" open style={{position: "relative"}}>
            <MDBRow>
                <MDBCol size="auto">
                    <Icon path={mdiClose} color="var(--mdb-danger)" size={5}/>
                </MDBCol>
                <MDBCol className="p-3">
                    <div>
                        <p>Deze installatie bevat een IoT-enabled toestel dat niet geactiveerd kon worden.</p>

                    </div>
                    <div>
                        <p>{t("Labels.deze-installatie-bevat-een-iot-enabled-toestel-dat-niet-geactiveerd-kon-worden", "Deze installatie bevat een IoT-enabled toestel dat niet geactiveerd kon worden.")}</p>
                        <p>
                            <Trans i18nKey="Labels.deze-hardware-kan-niet-geinstalleerd-worden-en-mag-niet-bij-de-klant-achtergelaten-worden">
                                Deze hardware kan niet geïnstalleerd worden en mag <strong>niet</strong> bij de klant achtergelaten worden.
                            </Trans>
                        </p>
                    </div>
                    <div>
                        <div>
                            <strong>{t("Labels.iot.platform", "Platform:")} </strong> {iot?.informatie?.iotPlatformName}
                        </div>
                        <div>
                            <strong>{t("Labels.iot.hardware-id", "Hardware-id:")} </strong> {iot?.informatie?.hardwareId}
                        </div>
                        <div><strong>{t("Labels.iot.reden", "Reden:")} </strong> {iot?.informatie?.failedReason}</div>
                    </div>
                    <div className="mt-2">
                        <MDBBtn color="primary" type="button"
                                onClick={onActiverenClick}>{t("Labels.activeren", "Activeren")}</MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
            <div style={{position: "absolute", right: 0, top: 12, bottom: 12, width: 160}}>
                <FaucetSignalIcon/>
            </div>
        </MDBAlert>
    );
};
