import React, {useCallback, useMemo} from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {Header} from "../../../components/Header";
import Icon from "@mdi/react";
import {mdiAlarmLightOutline, mdiLightbulbOnOutline} from "@mdi/js";
import {GenericPage} from "../../../components/GenericPage";
import {useParams} from "react-router-dom";
import {Adres} from "../../../components/Adres";
import {useToestel, useToestelOnderhoudUitgevoerd} from "../../../redux/slices/toestellen/hooks";
import {useBezoekSessieById, useBezoekSessieToestel} from "../../../redux/slices/bezoeksessie/hooks";
import {JaNee} from "../../../components/JaNee";
import {Form, Formik} from "formik";
import {
    ArtikelRestModelTypeEnum,
    ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum,
    ToestelOnderhoudUitgevoerdFilter
} from "../../../_generated/field-service-be-openapi";
import {useArtikelen} from "../../../redux/slices/artikel/hooks";
import * as Yup from "yup";
import {AnySchema} from "yup";
import {BezoekSessieGeslotenMelding} from "../../../components/BezoekSessieGeslotenMelding";
import {BezoekSessieStatus} from "../../../workers/shared/snapshot/bezoekSessieState";
import {ContentContainer} from "../../../components/ContentContainer";
import {BezoekMDBBtn} from "../../../components/bezoek/BezoekMDBBtn";
import {BezoekMDBFormikCheckbox} from "../../../mdb-formik/bezoek/BezoekMDBFormikCheckbox";
import {BezoekMDBFormikSelect} from "../../../mdb-formik/bezoek/BezoekMDBFormikSelect";
import {Datum} from "../../../components/Datum";
import {DisableAutocompletePlaceholderInput} from "../../../components/DisableAutocompletePlaceholderInput";
import {useTranslation} from "../../../utilities/i18nUtils";
import {BezoekMDBFormikTextArea} from "../../../mdb-formik/bezoek/BezoekMDBFormikTextArea";
import {OnbewaardeGegevensMelding} from "../../../components/OnbewaardeGegevensMelding";
import {SaveDraftFormik} from "../../../components/SaveDraftFormik";
import {useGoBack} from "../../../routes/useGoBack";

export interface OnderhoudFormValues {
    reinigenEnSpoelen: boolean | null;
    filtersVervangen: boolean | null;
    /**
     * Key: component-id
     */
    filtersVervangenArtikelen: Record<string, ToestelOnderhoudUitgevoerdFilter> | null;
    co2Vervangen: boolean | null;
    uvVervangen: boolean | null;
    tappuntWisselen: boolean | null;
    controleWerkingDone: boolean | null;
    controleWerkingResultaat: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum | null;
    controleWerkingOpmerking: string | null;
}

export interface OnderhoudPageParams {
    bezoekSessieId: string;
    toestelId: string;
}

export const OnderhoudPage: React.FC = () => {
    const {bezoekSessieId, toestelId} = useParams<OnderhoudPageParams>();
    const goBack = useGoBack();

    const artikelen = useArtikelen();

    const toestel = useToestel(toestelId);
    const bezoekSessie = useBezoekSessieById(bezoekSessieId);
    const bezoekSessieToestel = useBezoekSessieToestel(bezoekSessie?.id, toestelId);
    const serviceAdres = bezoekSessie?.serviceAdres;

    const toestelOnderhoudUitgevoerd = useToestelOnderhoudUitgevoerd();

    const onderhoud = bezoekSessie?.uitTeVoerenWerk?.toestellen?.find((item) => item.toestelId === toestelId)?.onderhoud;
    const stappen = onderhoud?.stappen;

    const {t} = useTranslation("serviceadres");

    const filterIsVerplichtLabel = t("OnderhoudPage.filter-is-verplicht", "Filter is verplicht");
    const resultaatIsVerplichtLabel = t("OnderhoudPage.resultaat-is-verplicht", "Resultaat is verplicht");
    const reinigenEnSpoelenLabel = t("OnderhoudPage.reinigen-en-spoelen", "Reinigen en spoelen");
    const filterVervangenLabel = t("OnderhoudPage.filter-vervangen", "Filter vervangen");
    const filterLabel = t("algemeen:filter", "Filter");
    const co2VervangenLabel = t("OnderhoudPage.co2-vervangen", "CO₂ vervangen");
    const uvVervangenLabel = t("OnderhoudPage.uv-vervangen", "UV vervangen");
    const tappuntWisselenLabel = t("OnderhoudPage.tappunt-wisselen", "Tappunt wisselen")
    const werkingTestLabel = t("OnderhoudPage.werking-test", "Werking test");
    const resultaatLabel = t("algemeen:resultaat", "Resultaat");
    const opmerkingLabel = t("algemeen:opmerking", "Opmerking");

    const onSubmit = useCallback((values: OnderhoudFormValues) => {
        if (bezoekSessie) {
            toestelOnderhoudUitgevoerd(bezoekSessie.id, toestelId, {
                controleWerking: values.controleWerkingDone ? {
                    resultaat: values.controleWerkingResultaat || undefined,
                    opmerking: values.controleWerkingOpmerking || undefined
                } : undefined,
                filtersVervangen: values.filtersVervangen ? Object.entries(values.filtersVervangenArtikelen || {}).map(([componentId, item]) => ({
                    componentId,
                    nieuweFilterArtikelId: item.nieuweFilterArtikelId === "geen" ? undefined : item.nieuweFilterArtikelId
                })) : undefined,
                reinigenEnSpoelen: values.reinigenEnSpoelen !== null ? values.reinigenEnSpoelen : undefined,
                uvVervangen: values.uvVervangen !== null ? values.uvVervangen : undefined,
                co2Vervangen: values.co2Vervangen !== null ? values.co2Vervangen : undefined,
                tappuntWisselen: values.tappuntWisselen !== null ? values.tappuntWisselen : undefined
            });

            goBack();
        }
    }, [bezoekSessie, toestelOnderhoudUitgevoerd, toestelId, goBack]);

    const initialValues = useMemo<OnderhoudFormValues>(() => {
        const form = bezoekSessie?.toestellen?.[toestel?.id]?.onderhoud?.form;

        const filtersVervangenArtikelen: Record<string, ToestelOnderhoudUitgevoerdFilter> = {};
        for (const filter of form?.uitgevoerdeStappen?.filtersVervangen || []) {
            const filterCopy = {...filter};
            if (!filterCopy.nieuweFilterArtikelId) {
                filterCopy.nieuweFilterArtikelId = "geen";
            }

            filtersVervangenArtikelen[filter.componentId!] = filterCopy;
        }

        return {
            reinigenEnSpoelen: form?.uitgevoerdeStappen?.reinigenEnSpoelen || false,
            uvVervangen: form?.uitgevoerdeStappen?.uvVervangen || false,
            co2Vervangen: form?.uitgevoerdeStappen?.co2Vervangen || false,
            tappuntWisselen: form?.uitgevoerdeStappen?.tappuntWisselen || false,
            controleWerkingDone: !!form?.uitgevoerdeStappen?.controleWerking?.resultaat,
            controleWerkingResultaat: form?.uitgevoerdeStappen?.controleWerking?.resultaat || null,
            controleWerkingOpmerking: form?.uitgevoerdeStappen?.controleWerking?.opmerking || "",
            filtersVervangen: !!form?.uitgevoerdeStappen?.filtersVervangen,
            filtersVervangenArtikelen: filtersVervangenArtikelen
        };
    }, [bezoekSessie, toestel]);

    const validationSchema = useMemo(() => Yup.lazy(obj => {
        const filtersVervangen: Record<string, AnySchema> = {};

        const componentIds = stappen?.filtersVervangen?.map(item => item.componentId!) || [];
        for (const key of componentIds) {
            if (obj.filtersVervangen) {
                filtersVervangen[key] = Yup.object({
                    nieuweFilterArtikelId: Yup.string().nullable().defined(filterIsVerplichtLabel)
                });
            }
        }

        return Yup.object({
            reinigenEnSpoelen: Yup.boolean().nullable(),
            filtersVervangen: Yup.boolean().nullable(),
            filtersVervangenArtikelen: Yup.object(filtersVervangen).nullable(),
            co2Vervangen: Yup.boolean().nullable(),
            uvVervangen: Yup.boolean().nullable(),
            tappuntWisselen: Yup.boolean().nullable(),
            controleWerkingDone: Yup.boolean().nullable(),
            controleWerkingResultaat: Yup.string().nullable().when("controleWerkingDone", {
                is: true,
                then: Yup.string().required(resultaatIsVerplichtLabel).nullable()
            }),
            controleWerkingOpmerking: Yup.object().nullable()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [stappen]);

    const filterArtikelen = useMemo(() => {
        return Object.values(artikelen || {})
            .filter((item) =>
                item.type === ArtikelRestModelTypeEnum.Filter &&
                item.bedrijfId === serviceAdres?.bedrijfId
            );
    }, [artikelen, serviceAdres]);

    const filterArtikelenData = useMemo(() => {
        const geenNieuweFilterOption = {
            text: t("OnderhoudPage.geen-nieuwe-filter", "Geen nieuwe filter"),
            value: "geen"
        };

        const filters = filterArtikelen
            .sort((a, b) => a.nummer.localeCompare(b.nummer))
            .map((item) => ({
                text: `${item.nummer} - ${item.omschrijving}`,
                value: item.id
            }));

        return [geenNieuweFilterOption, ...filters];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterArtikelen]);

    if (!serviceAdres || !toestel || !onderhoud) {
        return null;
    }

    return (
        <GenericPage>
            <Header title={t("OnderhoudPage.onderhoud-titel", "Onderhoud {{plaats}}",
                {plaats: bezoekSessieToestel?.informatie?.locatieBeschrijving || ""})}
                    subtitle={<><strong>{serviceAdres.naam}</strong> <Adres adres={serviceAdres}/></>}/>

            <ContentContainer>
                <BezoekSessieGeslotenMelding show={bezoekSessie?.status === BezoekSessieStatus.GESLOTEN}/>

                <MDBCard background="light" className="shadow-sm mb-4" style={{zIndex: 100}}>
                    <MDBCardHeader><Icon path={mdiAlarmLightOutline}
                                         size={1}/> {t("algemeen:Titels.onderhoud", "Onderhoud")}</MDBCardHeader>
                    <MDBCardBody>
                        <MDBCardText>
                            <MDBRow>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("algemeen:Labels:toestelnummer", "Toestelnummer:")}</strong><br/> {toestel.nr}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("algemeen:Labels.type", "Type:")}</strong><br/> {onderhoud.type}
                                </MDBCol>
                                <MDBCol sm="4" className="mb-2">
                                    <strong>{t("OnderhoudPage.Labels.filter-vervangen", "Filter vervangen:")}</strong><br/>
                                    <JaNee
                                        value={onderhoud.stappen?.filtersVervangen?.length}/>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                <Formik<OnderhoudFormValues> initialValues={initialValues} validationSchema={validationSchema}
                                             onSubmit={onSubmit} validateOnChange>
                    {
                        ({values, errors, dirty, isSubmitting}) => {
                            return (
                                <Form autoComplete="off">
                                    <OnbewaardeGegevensMelding
                                        draftValues={bezoekSessieToestel?.onderhoud?.draftValues}/>
                                    <SaveDraftFormik formId={`onderhoud/${toestel.id}`}
                                                     bezoekSessieId={bezoekSessieId}/>

                                    <DisableAutocompletePlaceholderInput/>
                                    <h5><Icon path={mdiLightbulbOnOutline} size={1}
                                              className="me-2"/> Controlelijst</h5>
                                    <hr/>

                                    <MDBRow>
                                        <MDBCol size="12" className="mb-3">
                                            <MDBListGroup>
                                                {stappen?.reinigenEnSpoelen && (
                                                    <MDBListGroupItem action
                                                                      color={values.reinigenEnSpoelen && !errors.reinigenEnSpoelen ? "success" : undefined}>
                                                        <BezoekMDBFormikCheckbox inline id="reinigenEnSpoelen"
                                                                                 name="reinigenEnSpoelen"
                                                                                 label={reinigenEnSpoelenLabel}/>
                                                    </MDBListGroupItem>
                                                )}
                                                {!!stappen?.filtersVervangen?.length && (
                                                    <MDBListGroupItem action
                                                                      color={values.filtersVervangen && !errors.filtersVervangenArtikelen ? "success" : undefined}>
                                                        <div className="mb-4">
                                                            <BezoekMDBFormikCheckbox inline id="filtersVervangen"
                                                                                     name="filtersVervangen"
                                                                                     label={filterVervangenLabel}/>
                                                        </div>

                                                        <div className="mx-n3">
                                                            <MDBTable className="table--no-bottom-border" striped>
                                                                <MDBTableHead>
                                                                    <tr>
                                                                        <th scope="col">{t("algemeen:Titels.artikelnr-omschrijving", "Artikelnr. - Omschrijving")}</th>
                                                                        <th scope="col">{t("algemeen:Titels.installatiedatum", "Installatiedatum")}</th>
                                                                        <th scope="col"/>
                                                                    </tr>
                                                                </MDBTableHead>
                                                                <MDBTableBody>
                                                                    {stappen.filtersVervangen.map((filter) => (
                                                                        <tr>
                                                                            <td className="align-middle">
                                                                                <strong>{filter.artikelNummer}</strong> {filter.artikelOmschrijving}
                                                                            </td>
                                                                            <td className="align-middle"><Datum
                                                                                datum={filter.installatieDatum}
                                                                                format="DD/MM/YYYY"/></td>
                                                                            <td className="align-middle text-right">
                                                                                <BezoekMDBFormikSelect
                                                                                    name={`filtersVervangenArtikelen.${filter.componentId}.nieuweFilterArtikelId`}
                                                                                    label={filterLabel}
                                                                                    data={filterArtikelenData}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </div>
                                                    </MDBListGroupItem>
                                                )}
                                                {stappen?.co2Vervangen && (
                                                    <MDBListGroupItem action
                                                                      color={values.co2Vervangen && !errors.co2Vervangen ? "success" : undefined}>
                                                        <BezoekMDBFormikCheckbox inline name="co2Vervangen"
                                                                                 id="co2Vervangen"
                                                                                 label={co2VervangenLabel}/>
                                                    </MDBListGroupItem>
                                                )}
                                                {stappen?.uvVervangen && (
                                                    <MDBListGroupItem action
                                                                      color={values.uvVervangen && !errors.uvVervangen ? "success" : undefined}>
                                                        <BezoekMDBFormikCheckbox inline name="uvVervangen"
                                                                                 id="uvVervangen"
                                                                                 label={uvVervangenLabel}/>
                                                    </MDBListGroupItem>
                                                )}
                                                {stappen?.tappuntWisselen && (
                                                    <MDBListGroupItem action
                                                                      color={values.tappuntWisselen && !errors.tappuntWisselen ? "success" : undefined}>
                                                        <BezoekMDBFormikCheckbox inline name="tappuntWisselen"
                                                                                 id="tappuntWisselen"
                                                                                 label={tappuntWisselenLabel}/>
                                                    </MDBListGroupItem>
                                                )}
                                                {stappen?.controleWerking && (
                                                    <MDBListGroupItem action
                                                                      color={values.controleWerkingDone && values.controleWerkingResultaat && !errors.controleWerkingResultaat ? "success" : undefined}>
                                                        <div className="mb-4">
                                                            <BezoekMDBFormikCheckbox inline name="controleWerkingDone"
                                                                                     id="controleWerkingDone"
                                                                                     label={werkingTestLabel}/>
                                                        </div>

                                                        <div>
                                                            <BezoekMDBFormikSelect
                                                                id="controleWerkingResultaat"
                                                                name="controleWerkingResultaat"
                                                                className="mb-4"
                                                                label={resultaatLabel}
                                                                data={[
                                                                    {
                                                                        text: t("ControleWerkingResultaat.ok", "Oké") as string,
                                                                        value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.Oke
                                                                    },
                                                                    {
                                                                        text: t("ControleWerkingResultaat.ok-maar-opvolging-vereist"
                                                                            , "Oké, maar opvolging vereist") as string,
                                                                        value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.OkeOpvolgingVereist
                                                                    },
                                                                    {
                                                                        text: t("ControleWerkingResultaat.defect-interventie-plannen",
                                                                            "Defect: interventie plannen") as string,
                                                                        value: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.Defect
                                                                    }
                                                                ]}
                                                            />

                                                            {values?.controleWerkingResultaat && [
                                                                    ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.OkeOpvolgingVereist,
                                                                    ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum.Defect]
                                                                    .includes(values?.controleWerkingResultaat) &&
                                                                <BezoekMDBFormikTextArea id="controleWerkingOpmerking"
                                                                                         name="controleWerkingOpmerking"
                                                                                         label={opmerkingLabel}
                                                                                         rows={4}/>}
                                                        </div>
                                                    </MDBListGroupItem>
                                                )}
                                            </MDBListGroup>
                                        </MDBCol>

                                        <MDBCol className="text-right">
                                            <BezoekMDBBtn block size="lg"
                                                          type="submit">{t("algemeen:bewaren", "Bewaren")}</BezoekMDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </Form>
                            );
                        }
                    }
                </Formik>

            </ContentContainer>
        </GenericPage>
    );
};
